import React from 'react';
import styled from "styled-components";
import CardImg from "../images/EmbeddedSwScreen3Card.png";
import LaptopSvg from "../svg/laptopSvg";
import PulleySvg from "../svg/pulleySvg";
import ChipSvg from "../svg/chipSvg";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding: 0 20px;
  padding-top: 150px;

  @media (max-width: 980px) {
    padding-top: 100px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: #023265;
  position: absolute;

  font-family: Montserrat, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  grid-area: 1 / 1 / 2 / 2;

  @media (max-width: 980px) {
    font-size: 26px;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 30px;
  margin-top: 124px;

  @media (max-width: 980px) {
    margin-top: 50px;
  }
`;

const Line = styled.div`
  display: flex;
  gap: 30px;
  position: relative;

  & > div:last-child {
    position: absolute;
    top: -124px;
    right: 0;

    @media (max-width: 980px) {
      position: relative;
      top: 0;
      right: 0;
    }
  }

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  padding: 70px 120px 70px 70px;

  text-align: center;
  font-family: Montserrat, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.7%; /* 33.154px */
  text-transform: uppercase;

  & > svg {
    position: absolute;
    right: 30px;
    top: 30px;

    @media (max-width: 980px) {
      position: static;
    }
  }

  @media (max-width: 1230px) {
    padding: 70px 120px 70px 70px;
  }

  @media (max-width: 980px) {
    flex-direction: column;
    gap: 30px;
    padding: 50px 30px;
  }
`;

const Card1 = styled(Card)`
  background: #023265;
  width: 493px;
  height: 200px;
  color: #FFF;

  @media (max-width: 1230px) {
    width: 440px;
    height: 238px;
  }

  @media (max-width: 980px) {
    width: 100%;
    height: auto;
  }
`;

const Card2 = styled(Card)`
  background: #0B6BD0;
  width: 659px;
  height: 206px;
  color: #FFF;

  @media (max-width: 1230px) {
    width: 490px;
    height: 272px;
  }

  @media (max-width: 980px) {
    width: 100%;
    height: auto;
  }
`;

const Card3 = styled(Card)`
  background: #F4B141;
  width: 659px;
  height: 275px;
  color: #000;
  
  @media (max-width: 1230px) {
    width: 530px;
    height: 305px;
  }

  @media (max-width: 980px) {
    width: 100%;
    height: auto;
  }
`;

const Card4 = styled(Card)`
  background: url(${CardImg});
  width: 490px;
  height: 359px;

  @media (max-width: 1230px) and (min-width: 980px) {
    width: 397px;
    height: 289px;

    top: -90px !important;
  }

  @media (max-width: 980px) {
    width: 100%;
    height: auto;
    background-size: cover;
    min-height: 233px;
    aspect-ratio: 2 / 1;
  }

  @media (max-width: 500px) {
    aspect-ratio: 220 / 233;
  }
`;

function EmbeddedSwScreen3(props) {
    return (
        <Component>
            <Content>
                <Title>Technology</Title>
                <Grid>
                    <Line>
                        <Card1><LaptopSvg/>Windows/ Linux/ FreeRTOS/ Bare Metal</Card1>
                        <Card2><PulleySvg/>BLE/ Ethernet/ WIFI/ i2C/ SPI/ADc/
                            DAC/ PCIe/CSI/MIPI DSI</Card2>
                    </Line>
                    <Line>
                        <Card3><ChipSvg/>STM32/ Nordic/ NXP IMX6 and IMX8/
                            Quectel/PIC/ Arduino/ DragonBoard 410c/ Rockchip/ Intel Atom/
                            xilinx and altera fpga</Card3>
                        <Card4/>
                    </Line>

                </Grid>
            </Content>
        </Component>
    );
}

export default EmbeddedSwScreen3;