import React from 'react';

function MainLogoTextSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="191" height="24" viewBox="0 0 191 24" fill="none">
            <path d="M17.9278 24L15.9477 19.0315H7.02185L5.04172 24H0L9.2 1H13.8L23 24H17.9278ZM11.5152 7.84406L8.83444 14.5236H14.1656L11.5152 7.84406Z" fill="white"/>
            <path d="M40.7486 3.05651C42.2495 4.42203 43 6.5279 43 9.37411C43 12.2203 42.2342 14.2933 40.6874 15.6094C39.1405 16.9256 36.7973 17.5837 33.627 17.5837H30.7784V24H26V1H33.5658C36.8432 1 39.2477 1.69099 40.7486 3.05651ZM37.2414 12.1052C37.8081 11.4142 38.0991 10.4106 38.0991 9.07797C38.0991 7.74535 37.7315 6.80758 36.9811 6.24821C36.2306 5.68884 35.0667 5.40916 33.5045 5.40916H30.7784V13.1416H33.9946C35.5874 13.1416 36.6595 12.7961 37.2414 12.1052Z" fill="white"/>
            <path d="M48 24V1H52.8106V19.4263H62V24H48Z" fill="white"/>
            <path d="M66 1H71V24H66V1Z" fill="white"/>
            <path d="M85.9022 5.44206V24H81.0978V5.44206H75V1H92V5.44206H85.9022Z" fill="white"/>
            <path d="M94 16V12H105V16H94Z" fill="white"/>
            <path d="M115.54 5.16183C115.08 5.57676 114.85 6.10788 114.85 6.78838C114.85 7.46888 115.126 8 115.693 8.39834C116.259 8.79668 117.546 9.26141 119.583 9.80913C121.62 10.3568 123.197 11.1701 124.315 12.249C125.433 13.3278 126 14.9212 126 16.9958C126 19.0705 125.28 20.7635 123.841 22.0581C122.401 23.3527 120.502 24 118.159 24C114.774 24 111.711 22.639 109 19.917L111.849 16.1328C114.161 18.3237 116.29 19.4191 118.25 19.4191C119.123 19.4191 119.813 19.2199 120.318 18.805C120.823 18.39 121.068 17.8423 121.068 17.1452C121.068 16.4481 120.808 15.9004 120.272 15.4855C119.736 15.0705 118.695 14.6556 117.117 14.2407C114.621 13.5934 112.798 12.7635 111.65 11.7344C110.501 10.7054 109.919 9.09544 109.919 6.88797C109.919 4.6805 110.654 2.98755 112.109 1.79253C113.579 0.59751 115.402 0 117.577 0C119.001 0 120.441 0.26556 121.865 0.796681C123.289 1.3278 124.545 2.07469 125.602 3.05394L123.182 6.83817C121.329 5.3112 119.414 4.54772 117.423 4.54772C116.627 4.54772 115.999 4.74689 115.54 5.16183Z" fill="white"/>
            <path d="M147.823 20.5596C145.71 22.8532 143.102 24 140 24C136.898 24 134.29 22.8532 132.177 20.5596C130.064 18.2659 129 15.4072 129 12C129 8.5928 130.064 5.73407 132.177 3.44044C134.29 1.14681 136.913 0 140 0C143.087 0 145.71 1.14681 147.823 3.44044C149.936 5.73407 151 8.5928 151 12C151 15.4072 149.951 18.2659 147.823 20.5596ZM146.234 12.0166C146.234 9.93906 145.635 8.17729 144.436 6.71468C143.237 5.25208 141.768 4.52078 140.015 4.52078C138.262 4.52078 136.793 5.25208 135.594 6.71468C134.395 8.17729 133.796 9.93906 133.796 12.0166C133.796 14.0942 134.395 15.856 135.594 17.3019C136.793 18.7479 138.262 19.4792 140.015 19.4792C141.768 19.4792 143.237 18.7479 144.436 17.3019C145.635 15.856 146.234 14.0942 146.234 12.0166Z" fill="white"/>
            <path d="M171 1V5.50787H160.875V10.4764H170.5V14.9843H160.875V24H156V1H171Z" fill="white"/>
            <path d="M184.902 5.44206V24H180.098V5.44206H174V1H191V5.44206H184.902Z" fill="white"/>
        </svg>
    );
}

export default MainLogoTextSvg;