import React, {useState} from 'react';
import styled, {StyleSheetManager} from "styled-components";
import CardImg from "../images/MainScreen5.png";
import PhoneSvg from "../svg/PhoneSvg";
import EmailSvg from "../svg/emailSvg";
import PlaceholderSvg from "../svg/placeholderSvg";
import {Button, Form, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";

const Component = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 173px;
  padding-bottom: 20px;

  @media (max-width: 860px) {
    padding-top: 100px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Title = styled.div`
  color: #F4B141;
  text-align: center;

  font-family: Montserrat, serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  display: flex;

  @media (max-width: 1230px) {
    font-size: 46px;
  }
  
  @media (max-width: 860px) {
    width: 100%;
    gap: 20px;
    align-items: center;
    font-size: 39px;
    display: none;
  }
`;


const ContactUs = styled.div`
  margin-top: 78px;
  display: flex;
  gap: 27px;


  @media (max-width: 860px) {
    flex-direction: column;
    margin-top: 0;
    gap: 100px;
  }
`;

const Card = styled.div`
  background: url(${CardImg}) no-repeat;
  background-size: cover;
  background-position: center;
  width: 573px;
  min-width: 573px;
  height: 457px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 73px;

  color: #FFF;
  font-family: Montserrat, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 1230px) {
    min-width: 50%;
    padding-right: 20px;
  }

  @media (max-width: 860px) {
    width: 100%;
    height: auto;
    padding: 48px 18px;
  }
`;

const ContactBlock = styled.div`
  color: #FFF;
  font-family: "DM Sans", serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 1230px) {
    svg {
      min-width: 40px;
      width: 40px;
    }
  }

  @media (max-width: 860px) {
    font-size: 18px;
  }
`;

const Contacts = styled.div`

`;

const Contact = styled.div`

`;

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;

  & .ant-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 860px) {
      height: auto;
      gap: 40px;
    }

    #message_help {
      position: absolute;
    }

    .ant-form-item-explain {
      position: absolute;
    }

    .ant-form-item-explain-error {
      margin-top: 10px;
    }
  }
  
  & ${Title} {
    display: none;
  }
  
  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
    & ${Title} {
      display: flex;
    }
  }

`;

const FormItem = styled(Form.Item)`
  height: auto;
  width: 100%;
  gap: 50px;
  margin: 0;

  .ant-row {
    flex-direction: column;
    min-height: 54px;
    height: auto;

    .ant-col {
      text-align: start;
      flex: 1 1 0 !important;


      label {
        color: rgba(0, 0, 0, 0.50);
        font-family: Montserrat, serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 5px;
        z-index: 1;
      }
    }

    input, textarea {
      border: 0;

      box-shadow: 0 0 0 #0000 !important;
      border-bottom: solid 2px rgba(2, 50, 101, 0.3);
      
      border-radius: 0;
      padding: 10px 0 20px;

      color: rgba(0, 0, 0, 1);
      font-family: Montserrat, serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      
      background-color: #0000;
      
      &::placeholder {
        color: rgba(0, 0, 0, 0.50);
        font-family: Montserrat, serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    textarea {
      resize: none;
    }
  }
  
  @media (max-width: 860px) {
    &:last-child {
      margin-top: 20px;
    }
  }

`;

const SubmitButton = styled(Button)`
  width: fit-content;
  border-radius: 20px;
  background: #F4B141;
  color: #E5E7EA;
  font-family: Montserrat, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 17px 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
`;

const SubLineForm = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;

  @media (max-width: 860px) {
    flex-direction: column;
    gap: 40px;
  }
`;

function MainScreen4(props) {

    const onFinish = (values) => {
        console.log('Form values:', values);
        sendToEmail(values)
    };

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const sendToEmail = (values) => {
        const url = "https://sender.aplit.tech/contact/Xt7MR02fznnv1Qht"
        setLoading(true);

        let bodyFormData = new FormData();
        Object.keys(values).forEach((key) => {
            bodyFormData.append(key, values[key])
        })

        console.log(bodyFormData)
        axios.post(url, bodyFormData)
            .then((response) => {
                console.log(response.data);
                setLoading(false);
                form.resetFields();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    return (
        <Component id={"contactus"}>
            <Content>
                <Title>contact us</Title>
                <ContactUs>
                    <Card>
                        Contact Info
                        <ContactBlock>
                            <PhoneSvg/>
                            <Contacts>
                                <Contact>+972-3-6205333</Contact>
                                <Contact>+972-52-2242065</Contact>
                            </Contacts>
                        </ContactBlock>
                        <ContactBlock>
                            <EmailSvg/>
                            <Contacts>
                                <Contact>office@aplit-soft.com</Contact>
                            </Contacts>
                        </ContactBlock>
                        <ContactBlock>
                            <PlaceholderSvg/>
                            <Contacts>
                                <Contact>Moshe Aviv 6, Or - Yehuda
                                    Israel 6037128</Contact>
                            </Contacts>
                        </ContactBlock>
                    </Card>
                    <FormWrapper>
                        <Title>contact us</Title>

                        <Form colon={false} requiredMark={false} onFinish={onFinish}>
                            <SubLineForm>
                                <FormItem name="name" rules={[{required: true, message: 'Please enter your name'}]}>
                                    <Input placeholder={"Name"}/>
                                </FormItem>
                                <FormItem name="phone"
                                          rules={[{required: true, message: 'Please enter your phone number'}]}>
                                    <Input placeholder={"Phone number"}/>
                                </FormItem>
                            </SubLineForm>

                            <FormItem name="email" rules={[{required: true,  type: 'email', message: 'Please enter your email'}]}>
                                <Input placeholder={"E-mail"}/>
                            </FormItem>
                            <FormItem name="message"
                                      rules={[{required: true, message: 'Please enter your message'}]}>
                                <TextArea placeholder={"Write your message here"} />
                            </FormItem>
                            <FormItem>
                                <SubmitButton type="primary" htmlType="submit" loading={loading}>
                                    Let’s get in touch
                                </SubmitButton>
                            </FormItem>
                        </Form>
                    </FormWrapper>
                </ContactUs>
            </Content>
        </Component>
    );
}

export default MainScreen4;