import React from 'react';
import styled from "styled-components";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding: 150px 0;
  margin-top: 150px;
  background: #023265;

  @media (max-width: 900px) {
    margin-top: 100px;
    padding: 100px 0;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* соотношение сторон 16:9 */
  position: relative;


`;

const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 80px;
  border: 0;

  @media (max-width: 900px) {
    border-radius: 40px;
  }
`;

function VideoPlayer(props) {
    const embedUrl = `https://www.youtube.com/embed/r1LcOqNcHhI?si=69vy6xJbq8VNH_Mg&amp;controls=0`;

    return (
        <Component>
            <Content>
                <VideoContainer>
                    <VideoFrame
                        src={embedUrl}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </VideoContainer>
            </Content>
        </Component>
    );
}

export default VideoPlayer;