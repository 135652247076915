import React from 'react';
import Header from "../components/Header";
import styled from "styled-components";
import Footer from "../components/Footer";
import AutomatedTestingScreen1 from "../components/AutomatedTestingScreen1";
import VideoPlayer from "../components/VideoPlayer";

const Page = styled.div`
  width: min(1920px, 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;


function AutomatedTestingPage(props) {
    return (
        <Page>
            <Header lightVersion/>
            <AutomatedTestingScreen1/>
            <VideoPlayer/>
            <Footer/>
        </Page>
    );
}

export default AutomatedTestingPage;