import React from 'react';
import styled from "styled-components";
import AlgorithmicImg from "../images/AlgorithmicScreen1.png";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding-top: 240px;

  @media (max-width: 900px) {
    padding-top: 200px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Title = styled.div`
  color: #F4B141;

  font-family: Montserrat, serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 32px;
  }
`;

const Grid = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 80px;

  & > img {
    border-radius: 50px;
    
    @media (max-width: 1230px) {
      width: 50%;
    }
    
    @media (max-width: 900px) {
      width: 100%;
      height: 461px;
      
      object-fit: cover;
    }

    @media (max-width: 500px) {
      height: 461px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 50px;
    gap: 30px;
  }
`;

const Text = styled.div`
  color: #FFF;
  font-family: Montserrat,serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 33px */

  display: flex;
  flex-direction: column;
  gap: 30px;

  border-radius: 50px;
  background: #023265;
  padding: 70px;

  @media (max-width: 1230px) {
    padding: 60px;
  }

  @media (max-width: 900px) {
    padding: 50px 20px 50px 30px;
  }
`;

function AlgorithmicScreen1(props) {
    return (
        <Component>
            <Content>
                <Title>Algorithmic</Title>
                <Grid>
                    <Text>
                        <p>
                            At Aplit-Soft, we understand that algorithms are the backbone of modern technology. Our
                            expertise in algorithmic solutions allows us to harness the power of algorithms to tackle
                            the
                            most challenging technological problems.
                        </p>
                        <p>
                            Whether you require algorithms for data analysis, machine learning, optimization, image
                            processing, or any other application, our team has the knowledge and experience to deliver
                            cutting-edge solutions.
                        </p>
                    </Text>
                    <img src={AlgorithmicImg} alt="AlgorithmicImg"/>
                </Grid>
            </Content>
        </Component>
    );
}

export default AlgorithmicScreen1;