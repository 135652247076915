import React from 'react';
import styled from "styled-components";
import Header from "../components/Header";
import MainScreen1 from "../components/MainScreen1";
import MainScreen2 from "../components/MainScreen2";
import MainScreen3 from "../components/MainScreen3";
import MainScreen4 from "../components/MainScreen4";
import Footer from "../components/Footer";

const Page = styled.div`
  width: min(1920px, 100%);
  position: relative;
`;

function Main(props) {
    return (
        <Page>
            <Header/>
            <MainScreen1/>
            <MainScreen2/>
            <MainScreen3/>
            <MainScreen4/>
            <Footer/>
        </Page>
    );
}

export default Main;