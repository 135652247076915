import React from 'react';

function CloseSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <path d="M12.8164 12.9218L29.9982 30" stroke="#023265" strokeWidth="2" strokeLinecap="round"/>
            <path d="M12 29.6597L29.1818 12.5815" stroke="#023265" strokeWidth="2" strokeLinecap="round"/>
            <rect x="1" y="1" width="40" height="40" rx="4" stroke="#0B6CD2" strokeWidth="2"/>
        </svg>
    );
}

export default CloseSvg;