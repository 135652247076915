import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import styled from "styled-components";

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import {Grid} from "swiper";

const Component = styled.div`
  width: 100%;

  margin-top: 50px;

  display: none;
  
  @media (max-width: 950px) {
    display: block;
  }

  & .swiper {
    width: 100%;
    border-radius: 30px;

    & .swiper-wrapper {
      background: #E5E7EA;
      height: 560px;

      .swiper-slide{
        width: 100%;
        height: calc((100%) / 4) !important;

        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid #0232654B;

        &:nth-child(4n) {
          border-bottom: 0 solid #0000;
        }
      }
      
    }

    & .swiper-pagination {
      position: static;
    }
  }
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 3px;
`;

const IndicatorDot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 2px;
  background-color: ${({isactive}) => (isactive ? '#F4B141' : '#E5E7EA')};
`;

function SwiperGridClient(props) {
    const Items = props.Logos
    const [currentSlide, setCurrentSlide] = useState(null);
    const [countSlides, setCountSlides] = useState(null);

    const onSwiperHandler = (e) => {
        setCurrentSlide(e.activeIndex)
        setCountSlides(e.snapGrid)
    }

    useEffect(() => {
    }, [currentSlide, countSlides])

    return (
        <Component>
            <Swiper
                slidesPerView={1}
                grid={{
                    rows: 4,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Grid]}
                className="mySwiper"
                onSlideChange={onSwiperHandler}
                onSwiper={onSwiperHandler}
            >

                {Items.map((value, index) => (
                    <SwiperSlide key={index}>
                        <img src={value} alt={value}/>
                    </SwiperSlide>
                ))}
            </Swiper>
            <IndicatorContainer>
                {countSlides && countSlides.map((value, index) => {
                    return <IndicatorDot key={index} isactive={index === currentSlide ? 1 : 0}/>
                })}
            </IndicatorContainer>

        </Component>
    );
}

export default SwiperGridClient;