import React from 'react';
import styled from "styled-components";
import MainLogoSvg from "../svg/MainLogoSvg";
import MainLogoTextSvg from "../svg/MainLogoTextSvg";
import IconInSvg from "../svg/IconInSvg";
import {Link} from "react-router-dom";

const Component = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 160px;
  padding-bottom: 150px;

  @media (max-width: 900px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const LogoTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #023265;
  font-family: "Open Sans", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  gap: 8px;

  @media (max-width: 1230px) {
    svg {
      max-width: 170px;
    }
    
    span {
      display: none;
    }
  }

  & > svg {
    width: 218px;

    & > path {
      fill: #023265;
    }
  }
  
  @media (max-width: 860px) {
    & > svg {
      width: 104px;
    }
  }
`;

const Logo = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  height: fit-content;
  margin-top: -24px;

  @media (max-width: 860px) {
    & > svg {
      width: 32px;
      margin-top: 0;
    }

    gap: 10px;
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;

  border-bottom: 2px solid rgba(2, 50, 101, 0.3);

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 44px;
  }
`;

const MiddleLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  max-width: 460px;
  row-gap: 31px;

  @media (max-width: 1230px) {
    grid-template-columns: 1fr 1fr;
    max-width: 274px;
  }
`;

const StyledLink = styled(Link)`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;

  @media (max-width: 1230px) and (min-width: 860px) {
    &:last-child{
      grid-area: 3 / 2 / 3 / 2;
    }
  }


  &:hover {
    text-decoration: underline;
  }
`;

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 31px;
  align-items: flex-end;

  @media (max-width: 860px) {
    align-items: flex-start;
  }
`;

const Copyright = styled.div`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 55px;
  
  text-align: center;

`;

const FollowUsText = styled.div`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;

`;

const LinkSvgWrapper = styled.a`
    
`;

function Footer(props) {
    return (
        <Component>
            <Content>
                <Menu>
                    <Logo to={"/"}>
                        <MainLogoSvg/>
                        <LogoTextContainer>
                            <MainLogoTextSvg/>
                            <span>Complex is Simple</span>
                        </LogoTextContainer>
                    </Logo>

                    <MiddleLinks>
                        <StyledLink to={"/#about"}>About</StyledLink>
                        <StyledLink to={"/#expertise"}>Expertise</StyledLink>
                        <StyledLink to={"/#contactus"}>Contact</StyledLink>
                        <StyledLink to={"/#services"}>Services</StyledLink>
                        <StyledLink to={"/#clients"}>Clients</StyledLink>
                    </MiddleLinks>

                    <LeftBlock>
                        <FollowUsText >Follow us on social media</FollowUsText>

                        <LinkSvgWrapper target="_blank" href={"https://www.linkedin.com/company/aplitsoft/"}>
                            <IconInSvg/>
                        </LinkSvgWrapper>
                    </LeftBlock>
                </Menu>

                <Copyright>
                    2023 APLIT-SOFT - All Rights Reserved
                </Copyright>
            </Content>
        </Component>
    );
}

export default Footer;