import React from 'react';
import Header from "../components/Header";
import styled from "styled-components";
import Footer from "../components/Footer";
import EmbeddedSwScreen1 from "../components/EmbeddedSwScreen1";
import EmbeddedSwScreen2 from "../components/EmbeddedSwScreen2";
import EmbeddedSwScreen3 from "../components/EmbeddedSwScreen3";
import Ticker from "../components/Ticker";

const Page = styled.div`
  width: min(1920px, 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

let TextForTicker1 = ["Custom Linux BSP and full software application on Variscite's SOM with NXP IMX6 and IMX8 Dual, connected to FPGA Altera Arria10. The project included data processing and development and implementation of vision algorithm",
"Reducing startup time of linux Yocto to 300 ms on Intel Atom",
"Porting Matlab code to C on Cortex A53 using Arm math librarie",
"Development of Drivers for different needs: PCIe DMA transaction drivers, virtual driver for CAM, etc.",
"Development of Embedded Drivers for different OS: Linux, Windows, Free RTOS.",
"Networking project: NXP Linux Yocto builder on LS2084 (quad cortex A72) and integration for Marvell Prestera PX PIPE port extender",
"Developing drivers for Omnivision, Sony IMX and Onsemi Aptina (CMOS image sensors), using MIPI CSI",
]

function EmbeddedSwPage(props) {
    return (
        <Page>
            <Header lightVersion/>
            <EmbeddedSwScreen1/>
            <EmbeddedSwScreen2/>
            <EmbeddedSwScreen3/>
            <Ticker TextForTicker={TextForTicker1}/>
            <Footer/>
        </Page>
    );
}

export default EmbeddedSwPage;