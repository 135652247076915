import React, {useEffect, useState} from 'react';
import styled, {StyleSheetManager} from "styled-components";

const Component = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  padding-top: 150px;

  @media (max-width: 800px) {
    padding-top: 100px;
  }
`;

const Title = styled.div`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  border-radius: 0 20px 20px 0;
  background: #F4B141;
  z-index: 1;
  height: 100%;
  max-width: 423px;
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  @media (max-width: 1230px) {
    max-width: 250px;
  }

  @media (max-width: 800px) {
    font-size: 18px;
    border-radius: 20px;
    background: #F4B141;
    width: 152px;
    display: none;

  }

`;

const TitleMobile = styled(Title)`
  display: none;
  margin-left: 20px;
  margin-bottom: 30px;
  width: 152px;
  height: 56px;
  padding: 5px;
  position: static;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    display: flex;
  }
`;


const Wrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;

  position: relative;

  width: 100%;
  display: flex;
  align-items: center;
  height: 76px;
  background: #013064;

  @media (max-width: 800px) {
    height: 56px;
  }
`;

const Text = styled.span`
  color: #FFF;
  font-family: Inter, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.7%; /* 54.252px */
  text-transform: uppercase;

  display: flex;
  gap: ${props => props.spacebetweenprojects}px;

  animation: marquee ${props => (props.textwidth / 2) / props.pixelspersecond}s linear infinite;

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(calc(-50% - ${props => props.spacebetweenprojects / 2}px));
    }
  }

  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

const Project = styled.span`
  display: inline-block;
`;

function Ticker({TextForTicker}) {

    const [width, setWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            const textWidth = document.getElementById('marquee-text')?.offsetWidth;
            setWidth(textWidth);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Component>
            <TitleMobile>Projects:</TitleMobile>
            <Wrapper>
                <Title>Projects:</Title>
                <StyleSheetManager shouldForwardProp={() => true}>
                    <Text id={"marquee-text"}
                          textwidth={width}
                          pixelspersecond={135}
                          spacebetweenprojects={200}
                    >

                        {TextForTicker.map((value, index) => {
                            return <Project key={index}>{value}</Project>
                        })}

                        {TextForTicker.map((value, index) => {
                            return <Project key={"2" + index}>{value}</Project>
                        })}
                    </Text>
                </StyleSheetManager>
            </Wrapper>
        </Component>
    );
}

export default Ticker;