import React from 'react';
import styled, {StyleSheetManager} from "styled-components";
import CPUImg from "../images/CPU.png";
import ClientsLogo1Img from "../images/ClientsLogo 1.png";
import ClientsLogo2Img from "../images/ClientsLogo 2.png";
import ClientsLogo3Img from "../images/ClientsLogo 3.png";
import ClientsLogo4Img from "../images/ClientsLogo 4.png";
import ClientsLogo5Img from "../images/ClientsLogo 5.png";
import ClientsLogo6Img from "../images/ClientsLogo 6.png";
import ClientsLogo7Img from "../images/ClientsLogo 7.png";
import ClientsLogo8Img from "../images/ClientsLogo 8.png";
import ClientsLogo9Img from "../images/ClientsLogo 9.png";
import ClientsLogo10Img from "../images/ClientsLogo 10.png";
import ClientsLogo11Img from "../images/ClientsLogo 11.png";
import ClientsLogo12Img from "../images/ClientsLogo 12.png";
import ClientsLogo13Img from "../images/ClientsLogo 13.png";
import ClientsLogo14Img from "../images/ClientsLogo 14.png";
import ClientsLogo15Img from "../images/ClientsLogo 15.png";
import ClientsLogo16Img from "../images/ClientsLogo 16.png";
import ClientsLogo17Img from "../images/ClientsLogo 17.png";
import ClientsLogo18Img from "../images/ClientsLogo 18.png";
import ClientsLogo19Img from "../images/ClientsLogo 19.png";
import ClientsLogo20Img from "../images/ClientsLogo 20.png";
import ArrowOpenCardSvg from "../svg/ArrowOpenCardSvg";
import {Link} from "react-router-dom";
import SwiperGridClient from "./SwiperGridClient";

const Component = styled.div`
  display: flex;
  justify-content: center;
  background: #023265;
  width: 100%;
  margin-top: 150px;

  padding-top: 250px;
  padding-bottom: 150px;

  @media (max-width: 950px) {
    padding-top: 100px;
    margin-top: 100px;
    padding-bottom: 100px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Title = styled.div`

  color: #F4B141;
  white-space: nowrap;

  font-family: Montserrat, serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  display: flex;

  @media (max-width: 1230px) {
    font-size: 46px;
  }

  @media (max-width: 950px) {
    width: 100%;
    gap: 20px;
    align-items: center;
    font-size: 32px;
  }
`;

const OurExpertise = styled.div`
  display: flex;
  gap: 100px;
  margin-top: 80px;

  @media (max-width: 1230px) {
    gap: 20px;
  }

  @media (max-width: 950px) {
    gap: 50px;
    flex-direction: column-reverse;
    margin-top: 0;
  }
`;

const Text = styled.div`
  width: 100%;

  color: #FFF;
  font-family: Montserrat, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */

  position: relative;

  & p {
    margin-bottom: 30px;
  }

  & ${Title} {
    position: absolute;
    top: -148px;

    &:before {
      position: absolute;
      left: -60px;
    }

    @media (max-width: 950px) {
      position: static;
      margin-bottom: 50px;

      &:before {
        position: static;
      }
    }
  }
`;

const Image = styled.div`
  width: 570px;
  min-width: 570px;
  height: 100%;
  background: url(${CPUImg});
  background-size: cover;
  background-position: center;
  border-radius: 50px;

  @media (max-width: 1230px) {
    min-width: 50%;
  }

  @media (max-width: 950px) {
    height: 440px;
    width: 100%;
    min-width: auto;
  }
`;

const LinksList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  color: #E5E7EA;
  font-family: Montserrat, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  border-bottom: solid 3px;

  svg {
    width: 30px;

    path {
      fill: #E5E7EA;
    }
  }

  &:hover {
    color: #F4B141;
    border-bottom: solid 3px #F4B141;

    path {
      fill: #F4B141;
    }
  }
`;

const Clients = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding-top: 73px;

  @media (max-width: 950px) {
    margin-top: 50px;
    padding-top: 50px;
  }
`;

const ClientsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 80px;

  border-radius: 30px;
  background: #E5E7EA;

  @media (max-width: 1230px) {
    grid-template-columns: repeat(3, 1fr);

    & > div:last-child {
      grid-area: 7 / 3 / 7 / 3;
    }
  }
  @media (max-width: 950px) {
    display: none;
  }
`;

const ClientsGridItem = styled.div`
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-right: 3px solid #0232654B;
  border-bottom: 3px solid #0232654B;

  &:nth-child(4n) {
    border-right: 0 solid #023265;
  }

  &:nth-child(n + 17) {
    border-bottom: 0 solid #023265;
  }

  @media (max-width: 1230px) {

    border-right: 1.5px solid #0232654B;
    border-bottom: 1.5px solid #0232654B;
    border: 1.5px solid #0232654B;

    &:nth-child(4n) {
      border-right: 1.5px solid #0232654B;
    }

    &:nth-child(n + 17) {
      border-bottom: 1.5px solid #0232654B;
    }
  }
`;

const Logos = [
    ClientsLogo1Img,
    ClientsLogo2Img,
    ClientsLogo3Img,
    ClientsLogo4Img,
    ClientsLogo5Img,
    ClientsLogo6Img,
    ClientsLogo7Img,
    ClientsLogo8Img,
    ClientsLogo9Img,
    ClientsLogo10Img,
    ClientsLogo11Img,
    ClientsLogo12Img,
    ClientsLogo13Img,
    ClientsLogo14Img,
    ClientsLogo15Img,
    ClientsLogo16Img,
    ClientsLogo17Img,
    ClientsLogo18Img,
    ClientsLogo19Img,
    ClientsLogo20Img
]

function MainScreen3(props) {
    return (
        <Component id={"expertise"}>
            <Content>
                <OurExpertise>
                    <Image/>
                    <Text>
                        <StyleSheetManager shouldForwardProp={() => true}>
                            <Title number={3}>Our Expertise</Title>
                        </StyleSheetManager>
                        <p>Our technological expertise in a diverse range of areas, including Embedded Software,
                            Automated Testing, Algorithmic Solutions and Internet of Things (IoT) along with Cloud
                            service and Application layer development.</p>
                        <p>We are a team of skilled professionals who are passionate about delivering innovative and
                            cutting-edge solutions to meet the evolving needs of our clients.</p>

                        <LinksList>
                            <StyledLink to="/embeddedsw">Embedded SW <ArrowOpenCardSvg/></StyledLink>
                            <StyledLink to="/iot">IOT <ArrowOpenCardSvg/></StyledLink>
                            <StyledLink to="/algorithmic">Algorithmic <ArrowOpenCardSvg/></StyledLink>
                            <StyledLink to="/automatedtesting">Automated testing <ArrowOpenCardSvg/></StyledLink>
                        </LinksList>
                    </Text>
                </OurExpertise>

                <Clients id={"clients"}>
                    <StyleSheetManager shouldForwardProp={() => true}>
                        <Title number={4}>Clients</Title>
                    </StyleSheetManager>
                    <ClientsGrid>
                        {Logos.map((value, index) => {
                            return <ClientsGridItem key={index}><img src={value}
                                                                     alt={`${value}`}/></ClientsGridItem>
                        })}
                    </ClientsGrid>

                    <StyleSheetManager shouldForwardProp={() => true}>
                        <SwiperGridClient Logos={Logos}/>
                    </StyleSheetManager>
                </Clients>
            </Content>
        </Component>
    );
}

export default MainScreen3;