import React from 'react';

function MainLogoSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="81" height="81" viewBox="0 0 81 81" fill="none">
            <path d="M59.2129 71.82H79.5715L50.7501 9.04498L40.4883 31.3956L58.8861 71.4679L59.2129 71.82Z" fill="url(#paint0_linear_523_1748)"/>
            <path d="M21.7225 71.8199C24.041 69.1217 26.9158 66.9561 30.1499 65.4714C33.3841 63.9867 36.9012 63.218 40.4603 63.218C44.0194 63.218 47.5365 63.9867 50.7707 65.4714C54.0048 66.9561 56.8796 69.1217 59.1981 71.8199H79.571C73.2343 56.4644 58.1147 45.6545 40.4603 45.6545C22.8059 45.6545 7.68629 56.4644 1.34961 71.8199H21.7225Z" fill="url(#paint1_linear_523_1748)"/>
            <path d="M1.34961 71.82H21.8805L50.7121 9.04498H30.1812L1.34961 71.82Z" fill="url(#paint2_linear_523_1748)"/>
            <defs>
                <linearGradient id="paint0_linear_523_1748" x1="60.0299" y1="9.04498" x2="60.0299" y2="71.82" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5EACFF"/>
                    <stop offset="1" stopColor="#1117BD"/>
                </linearGradient>
                <linearGradient id="paint1_linear_523_1748" x1="54.4065" y1="55.4725" x2="23.2016" y2="63.359" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFD500"/>
                    <stop offset="1" stopColor="#E69904"/>
                </linearGradient>
                <linearGradient id="paint2_linear_523_1748" x1="26.0308" y1="9.04498" x2="17.2742" y2="71.8189" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0B6CD2"/>
                    <stop offset="1" stopColor="#033F7E"/>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default MainLogoSvg;