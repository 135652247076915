import React from 'react';
import styled from "styled-components";
import BackgroundImg from "../images/BackgroundMainScreen1.png";

const Component = styled.div`
  display: flex;
  justify-content: center;

  background: url(${BackgroundImg});
  background-position: 40%;
  
  width: 100%;
  height: 875px;

  @media (max-width: 920px) {
    height: auto;
    padding-bottom: 100px;
    background-size: cover;
    background-position: center;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 270px;
  padding: 0 20px;
`;

const Text = styled.div`
  color: #FFF;
  font-family: Inter, serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  max-width: 1066px;

  & > span {
    color: #F4B141;
  }

  @media (max-width: 1230px) {
    font-size: 46px;
  }
  
  @media (max-width: 540px) {
    font-size: 32px;
  }
`;

const Enumeration = styled.div`
  color: #F4B141;
  font-family: Inter, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  margin-top: 80px;

  @media (max-width: 540px) {
    font-size: 22px;
  }
`;

const List = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 25px;

  @media (max-width: 1230px) {
    max-width: 600px;
    flex-wrap: wrap;
  }
`;

const Item = styled.div`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  
  padding: 18px 25px;
  border-radius: 20px;
  background: #E5E7EA;

  @media (max-width: 540px) {
    font-size: 16px;
  }
`;

function MainScreen1(props) {
    return (
        <Component>
            <Content>
                <Text>Our Mission - <span>to embed professionals</span> in the development of system products to make
                    the world a
                    better place</Text>
                <Enumeration>Our Values:</Enumeration>
                <List>
                    <Item>Professionalism</Item>
                    <Item>Growth</Item>
                    <Item>Persistence</Item>
                    <Item>Trust</Item>
                    <Item>Flexibility</Item>
                </List>
            </Content>
        </Component>
    );
}

export default MainScreen1;