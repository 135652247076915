import React from 'react';
import CardImage from "../images/AutomatedTestingScreen1.png";
import styled from "styled-components";
import TickSquareSvg from "../svg/TickSquareSvg";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding-top: 240px;

  @media (max-width: 900px) {
    padding-top: 200px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Title = styled.div`
  color: #F4B141;

  font-family: Montserrat, serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 1230px) {
    font-size: 46px;
  }

  @media (max-width: 900px) {
    font-size: 32px;
  }
`;

const TextImage = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 80px;

  & > img {
    border-radius: 50px;

    @media (max-width: 1230px) {
      height: 471px;
      object-fit: cover
    }

    @media (max-width: 900px) {
      height: 242px;
    }
  }

  @media (max-width: 1230px) {
    flex-direction: column-reverse;
    gap: 80px;
  }

  @media (max-width: 900px) {
    margin-top: 50px;
    gap: 50px;
    flex-direction: column;
  }
`;

const Text = styled.div`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Thumbnail = styled.div`
  color: #023265;

  /* H3 */
  font-family: Montserrat, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-top: 150px;

  @media (max-width: 900px) {
    margin-top: 100px;
    font-size: 26px;
  }
`;


const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px 50px;
  margin-top: 80px;

  @media (max-width: 900px) {
    width: auto;
    margin-top: 50px;
    grid-template-columns: 1fr;

    svg {
      min-width: 28px;
      max-width: 28px;
      gap: 35px;
    }
  }
`;

const ItemList = styled.div`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 900px) {
    font-size: 22px;
    gap: 30px;
    align-items: flex-start;
  }
`;

function AutomatedTestingScreen1(props) {
    return (
        <Component>
            <Content>
                <Title>Automated testing</Title>
                <TextImage>
                    <img src={CardImage} alt="CardImage"/>
                    <Text>
                        <p>At Aplit-Soft, we understand the importance of efficient and comprehensive testing processes,
                            and we offer expertise in automating these processes for optimal results. By automating
                            repetitive and time-consuming testing tasks, we help businesses save valuable time and
                            resources while ensuring thorough and reliable testing coverage. </p>
                        <p>We develop custom test automation frameworks tailored to your specific software and hardware
                            requirements. These frameworks enable efficient test case creation, execution, and result
                            analysis, allowing for faster feedback and quicker identification of issues.</p>
                    </Text>
                </TextImage>

                <Thumbnail>We have an automated testing frameworks that can be customized per your unique requirements
                    in the following areas:</Thumbnail>
                <List>
                    <ItemList><TickSquareSvg/>HW Embedded systems</ItemList>
                    <ItemList><TickSquareSvg/>WEB systems</ItemList>
                </List>
            </Content>
        </Component>
    );
}

export default AutomatedTestingScreen1;