import React from 'react';
import styled from "styled-components";
import TickSquareSvg from "../svg/TickSquareSvg";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding-top: 150px;

  @media (max-width: 900px) {
    padding-top: 100px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Title = styled.div`
  color: #023265;

  font-family: Montserrat, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 900px) {
    font-size: 26px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px 50px;
  margin-top: 80px;
  width: 1077px;

  & svg {
    min-width: 42px;
  }

  @media (max-width: 1230px) {
    width: 100%;
  }

  @media (max-width: 900px) {
    width: auto;
    margin-top: 50px;

    svg {
      min-width: 28px;
      max-width: 28px;
      gap: 35px;
    }
  }
`;

const ItemList = styled.div`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 900px) {
    font-size: 22px;
    gap: 30px;
    align-items: flex-start;
  }
`;



function AlgorithmicScreen2(props) {
    return (
        <Component>
            <Content>
                <Title>Our Experience</Title>
                <List>
                    <ItemList><TickSquareSvg/>Creating a face recognition algorithms and implementing it on STM32 MP1 (Dual Arm Cortex A7) with Linux Yocto</ItemList>
                    <ItemList><TickSquareSvg/>Analyzing the changes in the availability of goods on the shelves. Based on a periodic photography with filtering a possible interference (obstacles)</ItemList>
                    <ItemList><TickSquareSvg/>Analyzing the availability of goods on the shelves in boxes using a video camera in real time</ItemList>
                    <ItemList><TickSquareSvg/>Developing a PoC for the identification of the egg producer, based </ItemList>
                    <ItemList><TickSquareSvg/>on the features of the egg pattern and overprinting of production data</ItemList>
                </List>
            </Content>
        </Component>
    );
}

export default AlgorithmicScreen2;