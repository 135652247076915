import React from 'react';

function DeveloppingSvg(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="83" viewBox="0 0 83 83" fill="none">
            <g clipPath="url(#clip0_431_6236)">
                <path d="M41.5 83.0001C64.4199 83.0001 83.0001 64.4199 83.0001 41.5C83.0001 18.5802 64.4199 0 41.5 0C18.5802 0 0 18.5802 0 41.5C0 64.4199 18.5802 83.0001 41.5 83.0001Z" fill="#E69904"/>
                <path d="M76.6971 19.505C69.3568 31.2158 56.3362 38.9971 41.5 38.9971C26.6637 38.9971 13.643 31.2158 6.30273 19.505C13.63 7.79424 26.6637 0 41.5 0C56.3362 0 69.3698 7.79424 76.6971 19.505Z" fill="#F4B141"/>
                <path d="M66.4342 24.4978V58.3455H16.5684V24.4978C16.5684 23.3931 17.4676 22.4939 18.5722 22.4939H64.4303C65.535 22.4939 66.4342 23.3931 66.4342 24.4978Z" fill="#20273A"/>
                <path d="M63.3801 26.8838V53.2725C63.3801 53.656 63.0801 53.9561 62.6967 53.9561H20.3045C19.9211 53.9561 19.6211 53.656 19.6211 53.2725V26.8838C19.6211 26.5004 19.9211 26.2003 20.3045 26.2003H62.6967C63.0801 26.2003 63.3801 26.5004 63.3801 26.8838Z" fill="#0F9BE8"/>
                <path d="M69.9153 58.7404C69.7153 61.591 67.3482 63.8248 64.4643 63.8248H18.538C15.6542 63.8248 13.287 61.591 13.087 58.7404C13.0703 58.5237 13.2537 58.3403 13.4704 58.3403H69.5319C69.7487 58.3403 69.932 58.5237 69.9153 58.7404Z" fill="white"/>
                <path d="M48.6949 58.3403C48.6949 59.8573 47.4613 61.0908 45.9443 61.0908H37.0592C35.5422 61.0908 34.3086 59.8573 34.3086 58.3403H48.6949Z" fill="#D0F6FF"/>
                <path d="M19.6443 61.0936C19.6443 61.6338 19.1942 62.0612 18.6542 62.0612C18.1141 62.0612 17.6641 61.6338 17.6641 61.0936C17.6641 60.5311 18.1141 60.1035 18.6542 60.1035C19.1942 60.1035 19.6443 60.5311 19.6443 61.0936Z" fill="#FF3445"/>
                <path d="M22.8748 61.0936C22.8748 61.6338 22.4247 62.0612 21.8847 62.0612C21.3446 62.0612 20.8945 61.6338 20.8945 61.0936C20.8945 60.5311 21.3446 60.1035 21.8847 60.1035C22.4247 60.1035 22.8748 60.5311 22.8748 61.0936Z" fill="#0F9BE8"/>
                <path d="M26.1033 61.0936C26.1033 61.6338 25.6532 62.0612 25.1132 62.0612C24.5731 62.0612 24.123 61.6338 24.123 61.0936C24.123 60.5311 24.5731 60.1035 25.1132 60.1035C25.6532 60.1035 26.1033 60.5311 26.1033 61.0936Z" fill="#FED110"/>
                <g opacity="0.19">
                    <path d="M69.9164 67.9849C69.9164 68.6817 57.1922 69.2469 41.5012 69.2469C25.8102 69.2469 13.0859 68.6817 13.0859 67.9849C13.0859 67.2881 25.8102 66.7307 41.5012 66.7307C57.1921 66.7307 69.9164 67.2881 69.9164 67.9849Z" fill="black"/>
                </g>
                <path d="M73.948 30.7776V34.5447C73.948 35.3768 73.2709 36.0539 72.4388 36.0539L66.4336 36.0539V35.1935L72.4388 35.1935C72.7967 35.1935 73.0877 34.9026 73.0877 34.5447V30.7776H73.948Z" fill="#20273A"/>
                <path d="M73.5196 29.3673C74.295 29.3673 74.9263 29.9987 74.9263 30.7795C74.9263 31.5549 74.295 32.1862 73.5196 32.1862C72.7388 32.1862 72.1074 31.5549 72.1074 30.7795C72.1074 29.9987 72.7388 29.3673 73.5196 29.3673Z" fill="#FED110"/>
                <path d="M73.948 45.6297V49.3968H73.0877V45.6297C73.0877 45.2722 72.7967 44.9812 72.4388 44.9812H66.4336V44.1208L72.4388 44.1209C73.2709 44.1209 73.948 44.7979 73.948 45.6297Z" fill="#20273A"/>
                <path d="M73.5196 50.8071C74.295 50.8071 74.9263 50.1757 74.9263 49.3949C74.9263 48.6195 74.295 47.9882 73.5196 47.9882C72.7388 47.9882 72.1074 48.6195 72.1074 49.3949C72.1074 50.1757 72.7388 50.8071 73.5196 50.8071Z" fill="#FF3445"/>
                <path d="M66.4336 39.6571H74.6986V40.5169H66.4336V39.6571Z" fill="#20273A"/>
                <path d="M75.3985 38.6776C76.1739 38.6776 76.8052 39.309 76.8052 40.0898C76.8052 40.8652 76.1739 41.4965 75.3985 41.4965C74.6177 41.4965 73.9863 40.8652 73.9863 40.0898C73.9863 39.309 74.6177 38.6776 75.3985 38.6776Z" fill="#0F9BE8"/>
                <path d="M9.05273 30.7776L9.05274 34.5447C9.05274 35.3768 9.72978 36.0539 10.5619 36.0539L16.5671 36.0539L16.5671 35.1935L10.5619 35.1935C10.204 35.1935 9.91307 34.9026 9.91307 34.5447L9.91306 30.7776H9.05273Z" fill="#20273A"/>
                <path d="M9.48089 29.3672C8.70554 29.3672 8.07422 29.9985 8.07422 30.7794C8.07422 31.5548 8.70554 32.1861 9.48089 32.1861C10.2618 32.1861 10.8931 31.5548 10.8931 30.7794C10.8931 29.9985 10.2618 29.3672 9.48089 29.3672Z" fill="#FED110"/>
                <path d="M9.05273 45.6297L9.05274 49.3968H9.91307L9.91306 45.6297C9.91306 45.2722 10.204 44.9812 10.5619 44.9812H16.5671L16.5671 44.1208L10.5619 44.1209C9.72978 44.1209 9.05273 44.7979 9.05273 45.6297Z" fill="#20273A"/>
                <path d="M9.48089 50.8072C8.70554 50.8072 8.07422 50.1758 8.07422 49.395C8.07422 48.6196 8.70554 47.9883 9.48089 47.9883C10.2617 47.9883 10.8931 48.6196 10.8931 49.395C10.8931 50.1758 10.2618 50.8072 9.48089 50.8072Z" fill="#FF3445"/>
                <path d="M8.30273 39.6571H16.5677V40.5169H8.30273V39.6571Z" fill="#20273A"/>
                <path d="M7.60198 38.6776C6.82663 38.6776 6.19531 39.309 6.19531 40.0898C6.19531 40.8652 6.82663 41.4965 7.60198 41.4965C8.38285 41.4965 9.0142 40.8652 9.0142 40.0898C9.0142 39.309 8.38284 38.6776 7.60198 38.6776Z" fill="#0F9BE8"/>
                <path d="M57.1976 30.697L55.0058 41.6296L57.0161 51.7582H36.9533L36.3957 48.4642H29.5093C28.5885 48.4642 27.8104 47.7509 27.7326 46.8171L27.123 38.8932H23.9587C23.4269 38.8932 23.0509 38.3486 23.2454 37.8557L26.6951 29.0888V27.2732C26.6951 18.8565 33.4259 12.009 41.7907 11.8274C51.7118 11.607 59.1429 20.9704 57.1976 30.697Z" fill="white"/>
                <path d="M51.5868 29.1493C51.3781 29.9639 51.0503 30.7488 50.633 31.464C49.6297 31.0666 48.4475 31.2752 47.6329 32.0898C46.8183 32.9045 46.6097 34.0866 47.0071 35.09C46.282 35.5072 45.517 35.8251 44.7023 36.0536C44.2653 35.0602 43.2818 34.3648 42.1294 34.3648C40.9671 34.3648 39.9836 35.0602 39.5565 36.0536C38.7319 35.8251 37.9669 35.5072 37.2418 35.09C37.6391 34.0866 37.4306 32.9045 36.616 32.0898C35.8013 31.2752 34.6192 31.0666 33.6159 31.464C33.1986 30.7488 32.8808 29.9639 32.6621 29.1493C33.6457 28.7221 34.3311 27.7287 34.3311 26.5863C34.3311 25.4439 33.6457 24.4405 32.6621 24.0233C32.8808 23.1987 33.1986 22.4239 33.6159 21.7086C34.6192 22.0961 35.8013 21.8874 36.616 21.0827C37.4306 20.2681 37.6391 19.086 37.2418 18.0727C37.9669 17.6654 38.7319 17.3475 39.5565 17.119C39.9836 18.1025 40.9671 18.8078 42.1294 18.8078C43.2818 18.8078 44.2653 18.1025 44.7023 17.119C45.517 17.3475 46.282 17.6654 47.0071 18.0727C46.6097 19.086 46.8183 20.2681 47.6329 21.0827C48.4475 21.8874 49.6297 22.0961 50.633 21.7086C51.0503 22.4239 51.3781 23.1987 51.5868 24.0233C50.6032 24.4405 49.9178 25.4439 49.9178 26.5863C49.9178 27.7287 50.6032 28.7221 51.5868 29.1493Z" fill="#FF3445"/>
                <path d="M46.769 26.5863C46.769 29.1493 44.6927 31.2156 42.1297 31.2156C39.5568 31.2156 37.4805 29.1493 37.4805 26.5863C37.4805 24.0233 39.5568 21.947 42.1297 21.947C44.6927 21.947 46.769 24.0233 46.769 26.5863Z" fill="#313B5A"/>
                <path d="M45.8248 26.5863C45.8248 28.6228 44.1657 30.2818 42.1293 30.2818C40.0829 30.2818 38.4238 28.6228 38.4238 26.5863C38.4238 24.5399 40.0829 22.8907 42.1293 22.8907C44.1657 22.8907 45.8248 24.5399 45.8248 26.5863Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_431_6236">
                    <rect width="83" height="83" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default DeveloppingSvg;