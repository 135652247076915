import React, {useEffect, useRef, useState} from 'react';
import styled, {StyleSheetManager} from "styled-components";
import MainLogoSvg from "../svg/MainLogoSvg";
import MainLogoTextSvg from "../svg/MainLogoTextSvg";
import {Dropdown, Space} from 'antd';
import {Link, useLocation} from "react-router-dom";
import TextalignJustifycenterSvg from "../svg/textalignJustifycenterSvg";
import MobileMenu from "./MobileMenu";

const Component = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 24px 20px 0;
`;

const Logo = styled(Link)`
  display: flex;
  text-decoration: none;

  @media (max-width: 1230px) {
    & > svg {
      width: 78px;
    }

    span {
      display: none;
    }
  }

  @media (max-width: 400px) {
    & > svg {
      width: 46px;
    }
  }
`;

const LogoTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #FFF;
  font-family: "Open Sans", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 8px;

  text-decoration: none;

  color: ${props => props.lightversion && " #023265"};

  & > svg {
    width: ${props => props.lightversion && " 218px"};

    & > path {
      fill: ${props => props.lightversion && " #023265"};
    }
  }

  @media (max-width: 1230px) {
    svg {
      width: 153px;
    }
  }

  @media (max-width: 400px) {
    svg {
      width: 94px;
    }
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: ${props => props.lightversion && "2px solid rgba(2, 50, 101, 0.3)"};
  padding-bottom: 10px;

  @media (max-width: 1230px) {
    gap: 10px;
  }
}

;
`;

const StyledLink = styled(Link)`
  color: #FFF;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: #F4B141;
  }

  @media (max-width: 1230px) {
    font-weight: 500;
  }
`;

const BlockLinks = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 145px;

  & > a {
    text-decoration: underline;
  }

  & ${StyledLink} {
    ${props => props.lightversion && "color: #023265;"}
    &:hover {
      ${props => props.lightversion && "color: #F4B141;"}
    }
  }

  @media (max-width: 1230px) {
    font-weight: 500;

    max-width: 622px;
    width: 100%;
    gap: 5px;
  }

  @media (max-width: 920px) {
    display: none;
  }
`;

const LeftLinks = styled.div`
  display: flex;
  gap: 50px;

  @media (max-width: 1230px) {
    max-width: 427px;
    width: 100%;
    gap: 5px;
    justify-content: space-between;
  }
`;

const DropDownButton = styled.div`
  color: #FFF;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 20px;
  margin-left: -20px;

  &:hover {
    color: #F4B141;
  }

  @media (max-width: 1230px) {
    font-weight: 500;
  }

  cursor: pointer;
`;

const StyledTextJustSvg = styled(TextalignJustifycenterSvg)`
  display: none;

  @media (max-width: 920px) {
    display: block;

    path {
      fill: ${props => props.lightversion && "#023265"};
    }
  }
`;

const DropDownItem = styled(Link)`
  color: #023265 !important;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  &:hover {
    color: #F4B141 !important;
  }
`;


const items = [
    {
        key: '1',
        label: (
            <DropDownItem to="/embeddedsw">
                Embedded SW
            </DropDownItem>

        ),
    }, {
        key: '2',
        label: (
            <DropDownItem to="/iot">
                IOT
            </DropDownItem>

        ),
    }, {
        key: '3',
        label: (
            <DropDownItem to="/algorithmic">
                Algorithmic
            </DropDownItem>

        ),
    }, {
        key: '4',
        label: (
            <DropDownItem to="/automatedtesting">
                Automated testing
            </DropDownItem>

        ),
    }
];

function Header(props) {
    const lightVersion = props.lightVersion

    const location = useLocation();
    const lastHash = useRef('');

    // listen to location change using useEffect with location as dependency
    // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
    useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                document
                    .getElementById(lastHash.current)
                    ?.scrollIntoView({behavior: 'smooth', block: 'start'});
                lastHash.current = '';
            }, 100);
        } else {
            window.scrollTo(0, 0);
        }

    }, [location]);

    const [menuVisible, setMenuVisible] = useState(false)

    function menuActive() {
        setMenuVisible(true)
    }

    function menuClose() {
        setMenuVisible(false)
    }

    return (
        <Component>
            <MobileMenu menuVisible={menuVisible} menuClose={menuClose}/>

            <StyleSheetManager shouldForwardProp={() => true}>
                <Content lightversion={lightVersion ? 1 : 0}>
                    <Logo to={"/"}>
                        <MainLogoSvg/>
                        <LogoTextContainer lightversion={lightVersion ? 1 : 0}>
                            <MainLogoTextSvg/>
                            <span>Complex is Simple</span>
                        </LogoTextContainer>
                    </Logo>
                    <StyledTextJustSvg lightversion={lightVersion ? 1 : 0} onClick={menuActive}/>
                    <BlockLinks lightversion={lightVersion ? 1 : 0}>
                        <LeftLinks>
                            <StyledLink to={"/#about"}>About</StyledLink>
                            <StyledLink to={"/#services"}>Services</StyledLink>

                            <Dropdown
                                arrow={true}
                                menu={{
                                    items,
                                }}
                                overlayClassName={"dropdownMainMenu"}
                            >
                                <DropDownButton onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <StyledLink to={"/#expertise"}>Expertise</StyledLink>
                                    </Space>
                                </DropDownButton>
                            </Dropdown>
                            <StyledLink to={"/#clients"}>Clients</StyledLink>
                        </LeftLinks>

                        <StyledLink to={"/#contactus"}>contact us</StyledLink>
                    </BlockLinks>
                </Content>
            </StyleSheetManager>
        </Component>
    );
}

export default Header;