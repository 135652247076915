import React from 'react';

function ChipSvg(props) {
    return (
        <svg {...props} width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M41.5 0C18.6166 0 0 18.6166 0 41.5C0 64.3834 18.6166 83 41.5 83C64.3834 83 83 64.3834 83 41.5C83 18.6166 64.3834 0 41.5 0Z" fill="#023265"/>
            <g clipPath="url(#clip0_417_107)">
                <path d="M25 18H28V25H25V18Z" fill="#FFA514"/>
                <path d="M31 18H34V25H31V18Z" fill="#FFA514"/>
                <path d="M37 18H40V25H37V18Z" fill="#FFA514"/>
                <path d="M42 18H45V25H42V18Z" fill="#E69904"/>
                <path d="M48 18H51V25H48V18Z" fill="#E69904"/>
                <path d="M54 18H57V25H54V18Z" fill="#E69904"/>
                <path d="M25 59H28V66H25V59Z" fill="#FFA514"/>
                <path d="M31 59H34V66H31V59Z" fill="#FFA514"/>
                <path d="M37 59H40V66H37V59Z" fill="#FFA514"/>
                <path d="M42 59H45V66H42V59Z" fill="#E69904"/>
                <path d="M48 59H51V66H48V59Z" fill="#E69904"/>
                <path d="M54 59H57V66H54V59Z" fill="#E69904"/>
                <path d="M58 26H65V29H58V26Z" fill="#E69904"/>
                <path d="M58 32H65V35H58V32Z" fill="#E69904"/>
                <path d="M58 37H65V40H58V37Z" fill="#E69904"/>
                <path d="M58 43H65V46H58V43Z" fill="#E69904"/>
                <path d="M58 49H65V52H58V49Z" fill="#E69904"/>
                <path d="M58 55H65V58H58V55Z" fill="#E69904"/>
                <path d="M17 26H24V29H17V26Z" fill="#FFA514"/>
                <path d="M17 32H24V35H17V32Z" fill="#FFA514"/>
                <path d="M17 38H24V41H17V38Z" fill="#FFA514"/>
                <path d="M17 43H24V46H17V43Z" fill="#FFA514"/>
                <path d="M17 49H24V52H17V49Z" fill="#FFA514"/>
                <path d="M17 55H24V58H17V55Z" fill="#FFA514"/>
                <path d="M41.5681 24L44 42.4924L41.5681 61H23V24H41.5681Z" fill="#FF8870"/>
                <path d="M41 24H59V61H41V24Z" fill="#FF583E"/>
                <path d="M30.7907 36H28V29H35V31.7907H30.7907V36Z" fill="white"/>
                <path d="M54 36H51.2093V31.7907H47V29H54V36Z" fill="#DEDEDE"/>
                <path d="M54 55H47V52.2093H51.2093V48H54V55Z" fill="#DEDEDE"/>
                <path d="M35 55H28V48H30.7907V52.2093H35V55Z" fill="white"/>
                <path d="M38 47.1519L40.1828 49C40.4771 48.7517 40.8682 48.614 41.2839 48.614L42 47.2769L41.2839 46C40.0438 46 38.8768 46.4095 38 47.1519Z" fill="white"/>
                <path d="M34 42.9633L36.0182 45C37.3732 43.6336 39.1744 42.8807 41.0899 42.8807L42 41.6526L41.0899 40C38.4113 40 35.8935 41.0524 34 42.9633Z" fill="white"/>
                <path d="M30 39.868L32.0092 42C34.5139 39.3423 37.8039 38.0135 41.0939 38.0135L42 36.4992L41.0939 35C37.0764 35 33.0589 36.6223 30 39.868Z" fill="white"/>
                <path d="M41 48.614C41.3798 48.614 41.7371 48.7517 42.006 49L44 47.1519C43.199 46.4095 42.1329 46 41 46V48.614Z" fill="#DEDEDE"/>
                <path d="M41 42.8807C42.8912 42.8807 44.6696 43.6336 46.0074 45L48 42.9633C46.1305 41.0524 43.6446 40 41 40V42.8807Z" fill="#DEDEDE"/>
                <path d="M41 35V38.0135C44.2621 38.0135 47.5243 39.3423 50.0078 42L52 39.868C48.967 36.6223 44.9835 35 41 35Z" fill="#DEDEDE"/>
            </g>
            <defs>
                <clipPath id="clip0_417_107">
                    <rect width="48" height="48" fill="white" transform="translate(17 18)"/>
                </clipPath>
            </defs>
        </svg>

    );
}

export default ChipSvg;