import React, {useState} from 'react';
import styled, {StyleSheetManager} from "styled-components";
import AboutImg from "../images/MainScreen2About.png";
import ArrowOpenCardSvg from "../svg/ArrowOpenCardSvg";
import CloudHandSvg from "../svg/CloudHandSvg";
import SimCardSvg from "../svg/SimCardSvg";
import SynchronizeSvg from "../svg/SynchronizeSvg";
import DeveloppingSvg from "../svg/DeveloppingSvg";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding: 150px 20px 0;

  @media (max-width: 950px) {
    padding: 100px 20px 0;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  width: 100%;

  color: #F4B141;
  /* H2 */
  font-family: Montserrat, serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  display: flex;

  display: ${props => props.mobile && "none"};

  @media (max-width: 1230px) {
    font-size: 46px;
  }

  @media (max-width: 950px) {
    width: 100%;
    display: ${props => props.pc && " none"};
    display: ${props => props.mobile && " flex"};
    gap: 20px;
    align-items: center;
    font-size: 39px;
  }
`;

const About = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;

  @media (max-width: 950px) {
    gap: 50px;
  }
`;

const AboutContent = styled.div`

  display: flex;
  gap: 30px;

  @media (max-width: 1230px) {
    gap: 10px;
  }

  @media (max-width: 830px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
  }
`;

const AboutText = styled.div`
  color: #0D3158;
  font-family: Montserrat, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;

  & a {
    color: #0D3158;
  }
`;

const AboutImage = styled.div`
  background: url(${AboutImg}) no-repeat;
  width: 370px;
  min-width: 370px;
  height: 367px;
  min-height: 367px;
  border-radius: 50px;

  @media (max-width: 830px) {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: auto;
    min-width: auto;
    min-height: auto;
    background-position: center;
  }
`;

const Services = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 75px;
  padding-top: 75px;
  gap: 30px;

  transition: .3s;

  & ${Title} {

    margin-top: 22px;
    transition: .3s;

    @media (max-width: 950px) {
      position: static;
      margin-left: 0;
      opacity: 1;
      width: fit-content;
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    margin-top: 50px;
    padding-top: 50px;
  }
`;

const CardTitle = styled.div`
  font-family: Montserrat, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 950px) {
    max-width: 220px;
  }
`;

const CardText = styled.div`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */

  display: flex;
  flex-direction: column;


`;

const Card = styled.div`
  border-radius: 50px;
  padding: 70px 67px 70px 70px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: relative;

  & > svg {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  & p {
    margin-top: 34px;
  }

  @media (max-width: 950px) {
    padding: 50px 30px 80px;
  }
`;

const CardEnd = styled(Card)`
  width: 670px;
  background: #023265;
  color: #FFF;

  @media (max-width: 1230px) {
    width: auto;
    
    & ${CardTitle} {
      max-width: 200px;
    }
  }
  
  @media (max-width: 950px) {
    width: 100%;
  }

  & ${CardText} {
    & svg {
      & path {
        fill: #E5E7EA;
      }
    }
  }
`;

const CardProof = styled(Card)`
  width: 470px;
  background: #F4B141;
  color: #0D3158;
  height: auto;
  transition: .3s;

  margin-top: 50px;

  @media (max-width: 1230px) {
    width: auto;
    font-weight: 500;
    height: fit-content;
  }

  @media (max-width: 950px) {
    width: 100%;
    min-width: auto;
    margin-top: 0;
  }
`;

const CardSystem = styled(Card)`
  width: 470px;
  
  background: #F4B141;
  color: #0D3158;
  transition: .3s;


  @media (max-width: 1230px) {
    width: auto;
    margin-top: ${props => props.openend ? "-20px" : "10px"};
  }

  @media (max-width: 950px) {
    width: 100%;
    min-width: auto;
    margin-top: 0;
  }
`;

const CardRandD = styled(Card)`
  width: 670px;
  
  background: #0B6BD0;
  color: #E5E7EA;

  & ${CardText} {
    & svg {
      & path {
        fill: #E5E7EA;
      }
    }
  }
  
  @media (max-width: 1230px) {
    width: auto;
  }
  
  @media (max-width: 950px) {
    width: 100%;
  }
`;


const ServicesColumn = styled.div`
  width: 470px;

  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;

  transition: .3s;

  &:last-child {
    align-items: flex-end;
  }

  @media (max-width: 1230px) {
    width: 50%;
  }
  
  @media (max-width: 950px) {
    flex-direction: column;
    width: 100%;
  }
`;

const OpenButton = styled.div`
  margin-top: 20px;
  margin-bottom: -40px;
  cursor: pointer;
  width: 50px;
  
  height: ${props => !props.open ? "40px" : "0"};
  opacity: ${props => !props.open ? "" : "0"};
  margin: ${props => !props.open ? "" : "0"};
  pointer-events: ${props => !props.open ? "" : "none"};

`;

const HiddenText = styled.p`
  transition: .3s;
  display: block;

  line-height: ${props => props.open ? "" : "1px"};
  opacity: ${props => props.open ? "" : "0"};
  margin: ${props => props.open ? "" : "0"};
  pointer-events: ${props => props.open ? "" : "none"};
  position: ${props => props.open ? "" : "absolute"};
  
`;

function MainScreen2(props) {

    const [openCardEnd, setOpenCardEnd] = useState(false)
    const [openCardProof, setOpenCardProof] = useState(false)
    const [openCardSystem, setOpenCardSystem] = useState(false)
    const [openCardCardRandD, setOpenCardRandD] = useState(false)

    return (
        <Component id={"about"}>
            <Content>
                <About>
                    <StyleSheetManager shouldForwardProp={() => true}>
                        <Title number={"1"}>About</Title>
                    </StyleSheetManager>
                    <AboutContent>
                        <AboutText>
                            <p>In 2011, we, former Intel managers, decided to establish a system solution company and to
                                help people smoothly integrate into Israel hi-tech industry at various stages of their
                                lives. Ever since, we’ve helped dozens of people who work today in the leading industry
                                companies. Many of them participated in «
                                <a target="_blank" href={"https://www.tlalimgroup.com/en/brand/masa/"}
                                   rel={"noreferrer"}>Masa Tlalim</a>» program.</p>
                            <p>We offer professionalism and experience along with continuous learning and growth. Our
                                commitment is to provide a full system solution and make your product reliable, robust
                                and cost-effective.</p>
                        </AboutText>
                        <AboutImage/>
                    </AboutContent>
                </About>

                <Services id={"services"} open={openCardProof}>
                    <StyleSheetManager shouldForwardProp={() => true}>
                        <Title mobile={1}>services</Title>
                    </StyleSheetManager>
                    <ServicesColumn>
                        <CardEnd>
                            <CloudHandSvg/>
                            <CardTitle>End-To - End solution</CardTitle>
                            <CardText>
                                <p>One of our core strengths lies in providing end-to-end solutions. We take pride in
                                    being a one-stop-shop for all your technology requirements.</p>

                                <OpenButton open={openCardEnd} onClick={() => {
                                    setOpenCardEnd(!openCardEnd)
                                }}>
                                    <ArrowOpenCardSvg/>
                                </OpenButton>

                                <HiddenText open={openCardEnd}>
                                    From initial conceptualization to implementation we work closely with our
                                    clients to design and deliver tailored solutions and ongoing support that
                                    address their
                                    unique needs and drive business growth.</HiddenText>

                            </CardText>
                        </CardEnd>
                        <CardSystem>
                            <SynchronizeSvg/>
                            <CardTitle>System Integration</CardTitle>
                            <CardText>
                                <p>Integration is another critical component of our technology services. We
                                    understand
                                    that many businesses operate in a complex ecosystem of software applications,
                                    platforms, and data sources.</p>

                                <OpenButton open={openCardSystem} onClick={() => {
                                    setOpenCardSystem(!openCardSystem)
                                }}>
                                    <ArrowOpenCardSvg/>
                                </OpenButton>

                                <HiddenText open={openCardSystem}>Our integration services ensure seamless
                                    connectivity and
                                    interoperability
                                    between
                                    various systems, enabling
                                    efficient and reliable data transfer and reducing the time and
                                    cost.</HiddenText>

                            </CardText>
                        </CardSystem>

                    </ServicesColumn>
                    <ServicesColumn>
                        <StyleSheetManager shouldForwardProp={() => true}>
                            <Title number={"2"} pc={1}>services</Title>
                        </StyleSheetManager>
                        <CardProof>
                            <SimCardSvg/>
                            <CardTitle>Proof of Concept</CardTitle>
                            <CardText>
                                <p>We understand that adopting new technologies can be daunting, and organizations often
                                    need to assess the feasibility and potential impact before making significant
                                    investments.</p>

                                <OpenButton open={openCardProof} onClick={() => {
                                    setOpenCardProof(!openCardProof)
                                }}>
                                    <ArrowOpenCardSvg/>
                                </OpenButton>

                                <HiddenText open={openCardProof}>Our proof of concept services allow you to test and
                                    validate innovative and
                                    complex
                                    ideas and technologies in a controlled environment, enabling you to
                                    make informed decisions and minimize risks.</HiddenText>

                            </CardText>
                        </CardProof>

                        <CardRandD>
                            <DeveloppingSvg/>
                            <CardTitle>R&D</CardTitle>
                            <CardText>
                                <p>We understand the importance of staying ahead in today's rapidly evolving landscape.
                                    That's why we offer comprehensive R&D services to help businesses like yours stay at
                                    the forefront of innovation.</p>

                                <OpenButton open={openCardCardRandD} onClick={() => {
                                    setOpenCardRandD(!openCardCardRandD)
                                }}>
                                    <ArrowOpenCardSvg/>
                                </OpenButton>

                                <HiddenText open={openCardCardRandD}>Our R&D services are designed to empower you with
                                    the knowledge, insights,
                                    and
                                    solutions needed to drive meaningful advancements in your business. Our dedicated
                                    R&D team combines expertise in various disciplines with a deep understanding of your
                                    specific needs, addressing your unique challenges and propel your business
                                    forward.</HiddenText>
                            </CardText>
                        </CardRandD>
                    </ServicesColumn>
                </Services>
            </Content>
        </Component>
    );
}

export default MainScreen2;