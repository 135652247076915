import React from 'react';
import styled from "styled-components";
import IoTImg from "../images/IoTScreen1.png";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding: 240px 20px 0;

  @media (max-width: 800px) {
    padding: 200px 0 0;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: #F4B141;

  font-family: Montserrat, serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 1230px) {
    font-size: 46px;
  }

  @media (max-width: 800px) {
    font-size: 32px;
    padding: 0 20px;
  }
`;

const Grid = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 80px;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 50px;
    padding: 0 20px;
  }
`;

const Text = styled.div`
  color: #023265;
  font-family: Montserrat,serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */
  
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledImg = styled.img`
  border-radius: 50px;
  margin-top: 80px;

  @media (max-width: 800px) {
    border-radius: 0;
    margin-top: 50px;
  }
`;

function IoTScreen1(props) {
    return (
        <Component>
            <Content>
                <Title>IoT</Title>
                <Grid>
                    <Text>
                        In today's interconnected world, the Internet of Things is as a game-changer, enabling seamless communication between devices and the exchange of valuable data. At Aplit-Soft, we specialize in developing and implementing cutting-edge IoT solutions.
                    </Text>
                    <Text>
                        Our IoT expertise covers the entire spectrum of IoT development, from device connectivity to cloud-based data analytics. We have a deep understanding of IoT protocols, sensor integration, data management and security, allowing us to deliver end-to-end IoT solutions that align with your specific business needs.
                    </Text>
                </Grid>
                <StyledImg src={IoTImg} alt="IoTImg"/>
            </Content>
        </Component>
    );
}

export default IoTScreen1;