import React from 'react';
import styled from "styled-components";
import TickSquareSvg from "../svg/TickSquareSvg";
import CloudDataSvg from "../svg/cloudDataSvg";
import SmartphoneSvg from "../svg/smartphoneSvg";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding-top: 150px;

  @media (max-width: 900px) {
    padding-top: 100px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Title = styled.div`
  color: #023265;

  font-family: Montserrat, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px 50px;
  margin-top: 80px;
  width: 882px;

  & svg {
    min-width: 42px;
  }

  @media (max-width: 900px) {
    width: auto;
    margin-top: 50px;

    svg {
      min-width: 28px;
      max-width: 28px;
      gap: 35px;
    }
  }
`;

const ItemList = styled.div`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 900px) {
    font-size: 22px;
    gap: 30px;
    align-items: flex-start;
  }
`;

const Column = styled.div`

  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 100%;

  @media (max-width: 1230px) {
    max-width: 100%;
  }

  @media (max-width: 700px) {
    gap: 50px;
  }
  
`;

const Cards = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 150px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
  
  @media (max-width: 700px) {
    margin-top: 100px;
    gap: 50px;
  }
  
`;

const Card = styled.div`
  text-align: center;
  font-family: Montserrat, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.7%; /* 33.154px */
  text-transform: uppercase;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 98px;
  height: 174px;

  border-radius: 50px;
  
  @media (max-width: 1230px) {
    padding: 0 60px;
  }

  @media (max-width: 500px) {

    height: auto;
    padding: 50px;
    
    & svg {
      width: 50px;
      min-width: 50px;
    }
  }
`;

const Card1 = styled(Card)`
  background: #0B6BD0;
  gap: 130px;
  color: #FFF;

  @media (max-width: 1230px) {
    gap: 80px;
  }
  
  @media (max-width: 900px) {
    justify-content: space-between;
    gap: 5px;
  }
`;

const Card2 = styled(Card)`
  background: #F4B141;
  justify-content: space-between;
  color: #000;
`;

function IoTScreen2(props) {
    return (
        <Component>
            <Content>
                <Title>Our Experience</Title>
                <List>
                    <ItemList><TickSquareSvg/>Implementation of the data collection for an embedded device(sensor:
                        actuator, accelerometer, gyroscope, etc. )</ItemList>
                    <ItemList><TickSquareSvg/>Implementation of the data exchange layer(BLE, WIFI, etc.)</ItemList>
                    <ItemList><TickSquareSvg/>Implementation of Cloud service, includes DBMS</ItemList>
                    <ItemList><TickSquareSvg/>Implementation of Multi-Level Application layer: WEB and Mobile</ItemList>
                </List>

                <Cards>
                    <Column>
                        <Title>Technology</Title>
                        <Card1>MQTT/TLS <CloudDataSvg/></Card1>
                    </Column>
                    <Column>
                        <Title>OS</Title>
                        <Card2>iOS/ Android/ Tizen <SmartphoneSvg/></Card2>
                    </Column>
                </Cards>
            </Content>
        </Component>
    );
}

export default IoTScreen2;