import React, {useState} from 'react';
import styled, {StyleSheetManager} from "styled-components";
import CloseSvg from "../svg/CloseSvg";
import {Link} from "react-router-dom";

const Component = styled.div`

  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;

  border-radius: 0 0 50px 50px;
  background: #E5E7EA;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.25);

  display: ${props => props.visible ? "flex" : "none"};

  transition: .3s;
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 20px 40px;

  transition: .3s;
`;

const Menu = styled.div`
  font-family: Montserrat, serif;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;

  display: flex;
  flex-direction: column;

  transition: .3s;
`;

const HashLink = styled(Link)`
  color: #023265;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;

  margin-top: 30px;

  &:first-child {
    margin-top: 40px;
  }

  &:last-child {
    border-top: 2px solid rgba(2, 50, 101, 0.20);
    text-decoration: underline;
    padding-top: 30px;
  }

  &:active {
    color: #F4B141;
  }
`;

const StyledLink = styled(Link)`
  color: #023265;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-top: 15px;

  &:active {
    color: #F4B141;
  }
`;

const CloserWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 81px;
  align-items: center;
`;


const DopdownMenu = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  margin-top: 30px;
  transition: .3s;

  color: ${props => props.open ? "#F4B141" : "#023265"};
`;

const HiddenMenu = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    border-top: 2px solid rgba(2, 50, 101, 0.20);
    text-decoration: underline;
    padding-top: 30px;
  }

  transition: .3s;
  user-select: none;

  overflow: hidden;


  & > * {
    transition: .3s;
    margin-top: ${props => props.open ? "" : "-10%"};
    opacity: ${props => props.open ? "1" : "0"};
  }

  pointer-events: ${props => props.open ? "auto" : "none"};
`;


function MobileMenu({menuVisible, menuClose}) {

    const [openedExpertise, setOpenedExpertise] = useState(false)

    return (
        <StyleSheetManager shouldForwardProp={() => true}>
            <Component visible={menuVisible ? 1 : 0}>
                <Content>
                    <CloserWrapper>
                        <CloseSvg onClick={menuClose}/>
                    </CloserWrapper>

                    <Menu>
                        <HashLink to={"/#about"}>About</HashLink>
                        <HashLink to={"/#services"}>ServiСes</HashLink>
                        <DopdownMenu open={openedExpertise} onClick={() => {
                            setOpenedExpertise(!openedExpertise)
                        }}>Expertise </DopdownMenu>

                        <HiddenMenu open={openedExpertise}>
                            <StyledLink to={"/embeddedsw"}>Embedded SW</StyledLink>

                            <StyledLink style={{
                                "textTransform": "none"
                            }} to={"/Iot"}>IoT</StyledLink>

                            <StyledLink to={"/algorithmic"}>Algorithmic</StyledLink>
                            <StyledLink to={"/automatedtesting"}>Automated testing</StyledLink>
                        </HiddenMenu>

                        <HashLink to={"/#clients"}>Clients</HashLink>
                        <HashLink to={"/#contactus"}>contact us</HashLink>
                    </Menu>
                </Content>
            </Component>
        </StyleSheetManager>
    );
}

export default MobileMenu;