import React from 'react';

function PhoneSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <g clipPath="url(#clip0_399_1224)">
                <path d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z" fill="#F4B141"/>
                <path d="M9.30409 28.383C8.75367 27.7436 9.00123 26.4595 9.64062 25.9151L11.48 24.6167C13.2451 23.3977 14.5654 22.3817 16.6957 24.7837C17.3028 25.4682 17.8076 27.3488 20.0795 26.0479C21.9629 24.9695 25.938 21.7967 26.0382 19.6303C26.0944 18.4136 25.0977 17.6699 24.5684 17.2092C22.7168 15.5976 23.7473 13.6103 25.2425 12.0543C25.2425 12.0543 26.4851 10.7487 26.9236 10.34C27.5388 9.76679 28.3334 9.97396 29.0375 10.548C29.0375 10.548 32.1222 13.0164 31.9962 17.2158C31.9059 20.2296 30.5612 22.6169 25.7069 27.3863C23.3108 29.7404 18.796 33.1923 13.1104 31.5902C11.8973 31.2483 11.4931 30.9255 9.30409 28.383Z" fill="#0B6CD2"/>
                <path d="M11.1023 29.8869L17 24.7833C16.7569 24.4392 16.2106 24 16.2106 24L10 29.0083C10 29.0083 10.6514 29.7107 10.9618 30L11.1023 29.8869Z" fill="#64798A"/>
                <path d="M30.0537 12L24 16.874C24 16.874 24.0363 17.0061 25.2255 18L31 13.3508C31 13.3508 30.3389 12.219 30.0537 12Z" fill="#64798A"/>
            </g>
            <defs>
                <clipPath id="clip0_399_1224">
                    <rect width="42" height="42" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default PhoneSvg;