import React from 'react';

function CloudHandSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="83" height="83" viewBox="0 0 83 83" fill="none">
            <g clipPath="url(#clip0_431_6667)">
                <path d="M41.5 0C64.4158 0 83 18.5842 83 41.5C83 64.4288 64.4158 83 41.5 83C18.5842 83 0 64.4288 0 41.5C0 18.5842 18.5842 0 41.5 0Z" fill="#005BBB"/>
                <path d="M41.499 0.000244141C56.7243 0.000244141 70.0303 8.19651 77.2539 20.4131C69.3948 31.6569 56.2964 38.9973 41.499 38.9973C26.7017 38.9973 13.6032 31.6569 5.74414 20.4131C12.9677 8.19651 26.2737 0.000244141 41.499 0.000244141Z" fill="#1C75D3"/>
                <path d="M67.3007 54.2438C67.3008 55.1742 66.7891 56.0425 65.975 56.4999C65.975 56.4999 50.6554 67.5403 43.8326 67.9281C39.4056 68.1764 27.4966 61.0133 27.4966 61.0133L27.4961 48.2445C27.4961 48.2445 31.3261 48.1359 35.8307 48.2443C39.087 48.3217 42.607 49.9729 45.3129 52.4149L51.3835 52.4147C52.8489 52.4147 54.0352 53.6008 54.0352 55.0661C54.0353 55.5932 53.8802 56.0894 53.6089 56.5003H53.6166L63.5714 51.9027C65.2925 51.0731 67.3007 52.3289 67.3007 54.2438Z" fill="#FFC0A4"/>
                <path d="M54.0339 55.0629C54.1254 55.7286 53.9026 56.4405 53.4654 57.0007C53.0187 57.5551 52.3344 57.9397 51.5956 58.0165C50.2275 58.1019 48.9351 58.1039 47.6012 58.1165C44.9529 58.1055 42.3046 58.033 39.6562 57.7141C42.3046 57.3948 44.9529 57.3224 47.601 57.311C48.9152 57.3149 50.2718 57.3448 51.5509 57.3913C52.7234 57.3877 53.8886 56.4109 54.0339 55.0629Z" fill="#FFA87A"/>
                <path d="M27.4955 46.2685L27.4961 62.9892C27.4961 63.3151 27.2319 63.5793 26.906 63.5794L16.2861 63.5797C15.9602 63.5797 15.696 63.3155 15.6959 62.9895L15.6953 46.2689C15.6953 45.9429 15.9595 45.6787 16.2855 45.6787L26.9053 45.6783C27.2312 45.6783 27.4955 45.9426 27.4955 46.2685Z" fill="#FF3445"/>
                <path d="M52.8393 54.9359C52.8796 55.4119 52.7023 55.8473 52.3958 56.1619C52.1137 56.4438 51.7267 56.6131 51.2993 56.6131L50.2995 56.6131C50.0818 56.6131 49.9044 56.436 49.9044 56.2183L49.9043 53.9124C49.9043 53.6943 50.0816 53.5171 50.2994 53.5171L51.2185 53.5171C52.0329 53.5171 52.7749 54.1139 52.8393 54.9359Z" fill="white"/>
                <g opacity="0.1">
                    <path d="M67.3948 71.5054C67.3948 72.3426 55.7992 73.0217 41.5001 73.0217C27.201 73.0217 15.6055 72.3426 15.6055 71.5054C15.6055 70.6682 27.201 69.9985 41.5001 69.9985C55.7992 69.9985 67.3948 70.6682 67.3948 71.5054Z" fill="black"/>
                </g>
                <path d="M25.9729 60.8077C25.9729 61.5672 25.358 62.1821 24.5985 62.1821C23.8571 62.1821 23.2422 61.5672 23.2422 60.8077C23.2422 60.048 23.8571 59.4332 24.5985 59.4332C25.358 59.4332 25.9729 60.048 25.9729 60.8077Z" fill="white"/>
                <path d="M52.8483 29.4962C53.1328 29.4962 53.3645 29.7279 53.3645 30.0124L53.3644 36.651L58.656 36.651C58.9405 36.651 59.1722 36.8827 59.1722 37.1672L59.1721 43.173C59.1721 43.4575 58.9405 43.6892 58.6559 43.6892C58.3714 43.6892 58.1397 43.4575 58.1397 43.173L58.1398 37.6834L52.8482 37.6834C52.5637 37.6834 52.332 37.4517 52.332 37.1672L52.3321 30.0124C52.3321 29.7279 52.5637 29.4962 52.8483 29.4962Z" fill="#20273A"/>
                <path d="M41.9334 29.4962C42.2179 29.4962 42.4496 29.7279 42.4496 30.0124L42.4495 37.1672C42.4495 37.4517 42.2179 37.6834 41.9334 37.6834L36.6418 37.6834L36.6418 43.173C36.6418 43.4575 36.4101 43.6892 36.1256 43.6892C35.841 43.6892 35.6094 43.4575 35.6094 43.173L35.6094 37.1672C35.6094 36.8827 35.8411 36.651 36.1256 36.651L41.4172 36.651L41.4172 30.0124C41.4172 29.7279 41.6489 29.4962 41.9334 29.4962Z" fill="#20273A"/>
                <path d="M47.3913 29.4961C47.6758 29.4961 47.9075 29.7277 47.9075 30.0123L47.9074 43.1729C47.9074 43.4574 47.6757 43.6891 47.3912 43.6891C47.1067 43.6891 46.875 43.4574 46.875 43.1729L46.8751 30.0123C46.8751 29.7277 47.1067 29.4961 47.3913 29.4961Z" fill="#20273A"/>
                <path d="M56.5723 44.0223C56.5723 42.8749 57.5006 41.9467 58.648 41.9467C59.8082 41.9467 60.7365 42.8749 60.7365 44.0223C60.7365 45.1826 59.8082 46.1108 58.6479 46.1108C57.5005 46.1108 56.5723 45.1826 56.5723 44.0223Z" fill="#B5E237"/>
                <path d="M45.3027 44.0223C45.3027 42.8749 46.2438 41.9467 47.3913 41.9467C48.5387 41.9467 49.4669 42.8749 49.4669 44.0223C49.4669 45.1826 48.5387 46.1108 47.3912 46.1108C46.2438 46.1108 45.3027 45.1826 45.3027 44.0223Z" fill="#FF3445"/>
                <path d="M34.0488 44.0223C34.0488 42.8749 34.977 41.9467 36.1245 41.9467C37.2719 41.9467 38.213 42.8749 38.213 44.0223C38.213 45.1826 37.2718 46.1108 36.1244 46.1108C34.977 46.1108 34.0488 45.1826 34.0488 44.0223Z" fill="#FED110"/>
                <path d="M66.0613 26.0151C66.0613 27.726 65.3675 29.2756 64.2462 30.397C63.1248 31.5183 61.5752 32.2121 59.8636 32.2121H34.5468C30.7424 32.2121 27.6582 29.1286 27.6582 25.3243V25.025C27.6582 21.6768 30.0479 18.8858 33.2154 18.2662C33.5762 15.8443 35.6643 13.9864 38.186 13.9864C38.8274 13.9864 39.4416 14.1064 40.0057 14.3262C41.8928 10.7454 45.6522 8.30469 49.9808 8.30469C56.2048 8.30469 61.2504 13.3503 61.2504 19.5744C61.2504 19.7071 61.2482 19.8391 61.2437 19.9711C64.0024 20.5982 66.0613 23.0666 66.0613 26.0151Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_431_6667">
                    <rect width="83" height="83" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default CloudHandSvg;