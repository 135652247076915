import React from 'react';

function LaptopSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="83" height="83" viewBox="0 0 83 83" fill="none">
            <g clipPath="url(#clip0_399_242)">
                <path d="M41.5 83C64.4198 83 83 64.4198 83 41.5C83 18.5802 64.4198 0 41.5 0C18.5802 0 0 18.5802 0 41.5C0 64.4198 18.5802 83 41.5 83Z" fill="#0B6CD2"/>
                <path d="M18 22V56.1538L41.32 59L45 39.2669L41.32 22H18Z" fill="#F4B141"/>
                <path d="M66 22H42V59L66 56.1538V22Z" fill="#E69904"/>
                <path d="M21 25V53H35.9039L37.1082 52.1887L38.3551 53H40.201L41.4266 52.2097L44 39L41.4266 25H28.3836L27.158 25.7586L25.9324 25H21Z" fill="white"/>
                <path d="M62 25H57.1706L55.9706 25.6325L54.7706 25H42V52.2097L43.2 53H45.0074L46.211 52.3148L47.4074 53H62V25Z" fill="#E9EDF5"/>
                <path d="M16 56V62H41.7775L43 59L41.7775 56.7815L36.1387 56H16Z" fill="#F4B141"/>
                <path d="M68 56V62H42V56.7815L47.6875 56H68Z" fill="#E69904"/>
                <path d="M36 56V59H41.3287L42 57.5L41.3287 56H36Z" fill="#E9EDF5"/>
                <path d="M42 56H48V59H42V56Z" fill="#CDD2E1"/>
                <path d="M35.7616 44.7778V42.2139C37.1471 41.703 38.1424 40.3272 38.1424 38.7134C38.1424 36.6658 36.5405 35 34.5712 35C32.602 35 31 36.6658 31 38.7134C31 40.3272 31.9953 41.703 33.3808 42.2139V45.8032L36.6192 49.1706V53H39V48.145L35.7616 44.7778ZM34.5712 37.4756C35.2277 37.4756 35.7616 38.0308 35.7616 38.7134C35.7616 39.396 35.2277 39.9512 34.5712 39.9512C33.9147 39.9512 33.3808 39.396 33.3808 38.7134C33.3808 38.0308 33.9147 37.4756 34.5712 37.4756Z" fill="#FF8870"/>
                <path d="M28.6667 28.4637V25H26.3333V28.4637C24.9755 28.9975 24 30.4347 24 32.1206C24 34.2598 25.57 36 27.5 36C29.43 36 31 34.2598 31 32.1206C31 30.4349 30.0245 28.9975 28.6667 28.4637ZM27.5 33.4138C26.8566 33.4138 26.3333 32.8337 26.3333 32.1206C26.3333 31.4075 26.8566 30.8275 27.5 30.8275C28.1434 30.8275 28.6667 31.4075 28.6667 32.1206C28.6667 32.8337 28.1432 33.4138 27.5 33.4138Z" fill="#0B6CD2"/>
                <path d="M42.1421 53L43 41.6774L42.1421 32.0323C41.3807 32.0323 40.7614 31.468 40.7614 30.7742C40.7614 30.0804 41.3807 29.5161 42.1421 29.5161L43 28.4298L42.1421 27C39.8582 27 38 28.693 38 30.7742C38 32.4142 39.1545 33.8127 40.7614 34.332V53H42.1421Z" fill="#0B6CD2"/>
                <path d="M42 52V31.8387C42.7353 31.8387 43.3333 31.2961 43.3333 30.629C43.3333 29.9619 42.7353 29.4194 42 29.4194V27C44.2055 27 46 28.6279 46 30.629C46 32.206 44.8852 33.5506 43.3333 34.05V52H42Z" fill="#023265"/>
                <path d="M45 48.145V53H47.3808V49.1704L50.6192 45.8031V42.2139C52.0047 41.7029 53 40.3271 53 38.7134C53 36.6657 51.398 35 49.4288 35C47.4595 35 45.8576 36.6657 45.8576 38.7134C45.8576 40.3271 46.8529 41.7029 48.2384 42.2139V44.7777L45 48.145ZM50.6192 38.7135C50.6192 39.3962 50.0853 39.9513 49.4288 39.9513C48.7723 39.9513 48.2384 39.3962 48.2384 38.7135C48.2384 38.0309 48.7723 37.4758 49.4288 37.4758C50.0853 37.4758 50.6192 38.0309 50.6192 38.7135Z" fill="#FF583E"/>
                <path d="M52 31.4733C52 33.418 53.57 35 55.5 35C57.43 35 59 33.418 59 31.4733C59 29.9407 58.0245 28.6341 56.6667 28.1488V25H54.3333V28.1488C52.9755 28.6341 52 29.9408 52 31.4733ZM54.3333 31.4733C54.3333 30.825 54.8566 30.2977 55.5 30.2977C56.1434 30.2977 56.6667 30.825 56.6667 31.4733C56.6667 32.1216 56.1434 32.6489 55.5 32.6489C54.8566 32.6489 54.3333 32.1216 54.3333 31.4733Z" fill="#023265"/>
            </g>
            <defs>
                <clipPath id="clip0_399_242">
                    <rect width="83" height="83" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default LaptopSvg;