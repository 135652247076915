import React from 'react';

function EmailSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <g clipPath="url(#clip0_399_1310)">
                <path d="M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42C32.598 42 42 32.598 42 21Z" fill="#044CB7"/>
                <path d="M29.5357 29H11.4643C10.1035 29 9 27.9578 9 26.6724V16.3276C9 15.0422 10.1035 14 11.4643 14H29.5357C30.8965 14 32 15.0422 32 16.3276V26.6724C32 27.9578 30.8965 29 29.5357 29Z" fill="url(#paint0_linear_399_1310)"/>
                <path d="M31 28.3115C30.5659 28.7367 29.9651 29 29.3022 29H11.6978C11.0349 29 10.4348 28.7367 10 28.3115L18.8022 19.6888C19.7398 18.7704 21.2595 18.7704 22.1971 19.6888L30.9993 28.3115H31Z" fill="url(#paint1_linear_399_1310)"/>
                <path d="M31 14.6885L22.1978 23.3112C21.2602 24.2296 19.7405 24.2296 18.8029 23.3112L10 14.6885C10.4341 14.2633 11.0349 14 11.6978 14H29.3022C29.9651 14 30.5652 14.2633 31 14.6885Z" fill="url(#paint2_linear_399_1310)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_399_1310" x1="9" y1="21.5" x2="32" y2="21.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFA514"/>
                    <stop offset="0.5" stopColor="#F56F00"/>
                    <stop offset="1" stopColor="#FFA514"/>
                </linearGradient>
                <linearGradient id="paint1_linear_399_1310" x1="20.5" y1="29" x2="20.5" y2="18.9997" gradientUnits="userSpaceOnUse">
                    <stop offset="0.1" stopColor="#FFCC30"/>
                    <stop offset="0.14" stopColor="#FFC82E"/>
                    <stop offset="0.44" stopColor="#FFB224"/>
                    <stop offset="0.73" stopColor="#FFA51E"/>
                    <stop offset="1" stopColor="#FFA11D"/>
                </linearGradient>
                <linearGradient id="paint2_linear_399_1310" x1="20.5" y1="24.0003" x2="20.5" y2="14" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE736"/>
                    <stop offset="0.35" stopColor="#FED426"/>
                    <stop offset="1" stopColor="#FCA800"/>
                </linearGradient>
                <clipPath id="clip0_399_1310">
                    <rect width="42" height="42" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default EmailSvg;