import React from 'react';

function SimCardSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="83" height="83" viewBox="0 0 83 83" fill="none">
            <g clipPath="url(#clip0_431_6491)">
                <path d="M83.2204 41.5C83.2204 64.4288 64.5453 83.0001 41.4998 83.0001C18.4673 83.0001 -0.220703 64.4288 -0.220703 41.5C-0.220703 35.69 0.972401 30.1654 3.13824 25.1464C9.53183 10.362 24.3033 0 41.4998 0C58.7094 0 73.4938 10.362 79.8614 25.1594C82.0272 30.1783 83.2204 35.703 83.2204 41.5Z" fill="#023265"/>
                <g opacity="0.13">
                    <path d="M66.605 72.252C66.605 73.1869 55.363 73.9453 41.4998 73.9453C27.6366 73.9453 16.3945 73.1869 16.3945 72.252C16.3945 71.3169 27.6366 70.569 41.4998 70.569C55.363 70.569 66.605 71.3169 66.605 72.252Z" fill="black"/>
                </g>
                <path d="M60.1562 15.8546L60.1562 65.3717C60.1562 66.7726 59.0205 67.9082 57.6197 67.9083L25.3803 67.9083C23.9794 67.9083 22.8437 66.7727 22.8438 65.3718L22.8438 15.8547C22.8438 14.4538 23.9794 13.3181 25.3803 13.3181L57.6197 13.3181C59.0206 13.3181 60.1562 14.4537 60.1562 15.8546Z" fill="white"/>
                <path d="M25.4902 58.1783L25.4903 23.0478C25.4903 22.5955 25.8569 22.2289 26.3091 22.2289L56.689 22.2289C57.1413 22.2289 57.5079 22.5955 57.5079 23.0477L57.5079 58.1782C57.5079 58.6305 57.1412 58.9971 56.689 58.9971L26.3091 58.9971C25.8568 58.9971 25.4902 58.6305 25.4902 58.1783Z" fill="#0F9BE8"/>
                <path d="M46.58 19.5004C46.58 19.8217 46.323 20.0787 46.0017 20.0787L36.9865 20.0787C36.6717 20.0787 36.4082 19.8217 36.4082 19.5004C36.4082 19.1791 36.6717 18.9221 36.9865 18.9221L46.0017 18.9221C46.323 18.9221 46.58 19.1791 46.58 19.5004Z" fill="#20273A"/>
                <path d="M42.3869 16.8583C42.3869 17.3435 41.9899 17.7405 41.5046 17.7405C41.0083 17.7405 40.6113 17.3435 40.6113 16.8583C40.6113 16.362 41.0083 15.965 41.5046 15.965C41.9899 15.965 42.3869 16.362 42.3869 16.8583Z" fill="#20273A"/>
                <path d="M43.8915 65.8944H39.1093C37.9973 65.8944 37.0918 64.9889 37.0918 63.8769C37.0918 62.7637 37.9973 61.8582 39.1093 61.8582H43.8915C45.0048 61.8582 45.9103 62.7637 45.9103 63.8769C45.9103 64.9889 45.0048 65.8944 43.8915 65.8944ZM39.1093 62.6358C38.4254 62.6358 37.8694 63.193 37.8694 63.8769C37.8694 64.5608 38.4254 65.1168 39.1093 65.1168H43.8915C44.5754 65.1168 45.1327 64.5608 45.1327 63.8769C45.1327 63.193 44.5754 62.6358 43.8915 62.6358H39.1093Z" fill="#20273A"/>
                <path d="M69.4194 26.9912L69.4194 32.6398C69.4194 33.8876 68.4047 34.9023 67.1569 34.9023L60.1563 34.9024L60.1562 33.6123L67.1569 33.6123C67.6931 33.6123 68.1294 33.176 68.1294 32.6398L68.1293 26.9912L69.4194 26.9912Z" fill="white"/>
                <path d="M68.7777 24.8768C69.9403 24.8768 70.887 25.8235 70.887 26.9944C70.887 28.157 69.9403 29.1037 68.7777 29.1037C67.6069 29.1037 66.6602 28.157 66.6602 26.9944C66.6602 25.8235 67.6068 24.8768 68.7777 24.8768Z" fill="#FF3445"/>
                <path d="M69.4194 48.5861L69.4195 54.2347L68.1294 54.2347L68.1294 48.5861C68.1294 48.0499 67.6931 47.6136 67.1569 47.6136L60.1563 47.6137L60.1562 46.3236L67.1569 46.3236C68.4047 46.3236 69.4194 47.3384 69.4194 48.5861Z" fill="white"/>
                <path d="M68.7777 56.3493C69.9403 56.3493 70.887 55.4026 70.887 54.2317C70.887 53.0691 69.9403 52.1224 68.7777 52.1224C67.6068 52.1224 66.6602 53.0691 66.6602 54.2318C66.6602 55.4026 67.6069 56.3493 68.7777 56.3493Z" fill="#FFA300"/>
                <path d="M60.1562 40.3018H74.1327V41.5921H60.1562V40.3018Z" fill="white"/>
                <path d="M75.182 38.8334C76.3446 38.8334 77.2912 39.7801 77.2913 40.9509C77.2913 42.1135 76.3446 43.0602 75.182 43.0602C74.0112 43.0602 73.0645 42.1136 73.0645 40.9509C73.0644 39.7801 74.0111 38.8334 75.182 38.8334Z" fill="#FED110"/>
                <path d="M13.5801 26.9913L13.5801 32.64C13.5801 33.8877 14.5948 34.9025 15.8426 34.9025L22.8432 34.9025L22.8432 33.6125L15.8426 33.6124C15.3064 33.6124 14.8701 33.1761 14.8701 32.64L14.8701 26.9913L13.5801 26.9913Z" fill="white"/>
                <path d="M14.2225 24.877C13.0599 24.877 12.1133 25.8236 12.1133 26.9945C12.1133 28.1571 13.0599 29.1038 14.2225 29.1038C15.3934 29.1038 16.3401 28.1571 16.3401 26.9945C16.3401 25.8237 15.3934 24.877 14.2225 24.877Z" fill="#FF3445"/>
                <path d="M13.5801 48.5861L13.5801 54.2347L14.8701 54.2347L14.8701 48.5861C14.8701 48.0499 15.3065 47.6136 15.8426 47.6136L22.8433 47.6137L22.8433 46.3236L15.8426 46.3236C14.5949 46.3236 13.5801 47.3384 13.5801 48.5861Z" fill="white"/>
                <path d="M14.2225 56.3494C13.0599 56.3494 12.1133 55.4027 12.1133 54.2319C12.1133 53.0692 13.0599 52.1226 14.2225 52.1226C15.3934 52.1226 16.3401 53.0693 16.3401 54.2319C16.3401 55.4027 15.3934 56.3494 14.2225 56.3494Z" fill="#FFA300"/>
                <path d="M8.86914 40.3019H22.8456V41.5923H8.86914V40.3019Z" fill="white"/>
                <path d="M7.81824 38.8335C6.65564 38.8335 5.70899 39.7802 5.70898 40.9511C5.70898 42.1137 6.65561 43.0604 7.81822 43.0604C8.98908 43.0604 9.93578 42.1137 9.93579 40.9511C9.93579 39.7802 8.98911 38.8335 7.81824 38.8335Z" fill="#FED110"/>
                <path d="M50.963 33.2044V52.4228C50.963 52.9557 50.5269 53.3918 49.9938 53.3918H33.0041C32.4712 53.3918 32.0352 52.9557 32.0352 52.4228V28.1818C32.0352 27.6488 32.4712 27.2128 33.0041 27.2128H44.9712C45.3911 27.2128 45.8111 27.3905 46.1018 27.6812L50.4946 32.0739C50.7851 32.3646 50.963 32.7845 50.963 33.2044Z" fill="#FF8870"/>
                <path d="M34.7812 39.6942V50.1396C34.7812 50.4192 35.0079 50.6459 35.2875 50.6459H47.7106C47.9902 50.6459 48.2169 50.4192 48.2169 50.1396V39.6942C48.2169 39.4146 47.9902 39.188 47.7106 39.188H35.2875C35.0079 39.188 34.7812 39.4146 34.7812 39.6942Z" fill="#FED110"/>
                <path d="M41.084 39.1915H41.914V50.6416H41.084V39.1915Z" fill="#FF9F00"/>
                <path d="M41.5 44.501H48.2178V45.331H41.5V44.501Z" fill="#FF9F00"/>
                <path d="M34.7812 42.3145H41.9141V43.1445H34.7812V42.3145Z" fill="#FF9F00"/>
                <path d="M34.7812 46.6888H41.9141V47.5188H34.7812V46.6888Z" fill="#FF9F00"/>
                <path d="M39.5048 29.3986C39.5048 29.5428 39.3869 29.6634 39.24 29.6634L34.4816 29.6634C34.3348 29.6634 34.2168 29.5428 34.2168 29.3986C34.2168 29.2518 34.3348 29.1338 34.4816 29.1338L39.24 29.1338C39.3869 29.1338 39.5048 29.2518 39.5048 29.3986Z" fill="#313B5A"/>
                <path d="M36.8112 30.8759C36.8112 31.0201 36.6932 31.1407 36.5464 31.1407H34.4816C34.3348 31.1407 34.2168 31.0201 34.2168 30.8759C34.2168 30.7291 34.3348 30.6111 34.4816 30.6111H36.5464C36.6932 30.6111 36.8112 30.7291 36.8112 30.8759Z" fill="#313B5A"/>
                <path d="M38.1538 30.8759C38.1538 31.0201 38.0358 31.1407 37.889 31.1407H37.5226C37.3758 31.1407 37.2578 31.0201 37.2578 30.8759C37.2578 30.7291 37.3758 30.6111 37.5226 30.6111H37.889C38.0358 30.6111 38.1538 30.7291 38.1538 30.8759Z" fill="#313B5A"/>
            </g>
            <defs>
                <clipPath id="clip0_431_6491">
                    <rect width="83" height="83" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SimCardSvg;