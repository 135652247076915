import React from 'react';

function SmartphoneSvg(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="83" viewBox="0 0 83 83" fill="none">
            <g clipPath="url(#clip0_399_359)">
                <path d="M41.5 83C64.4198 83 83 64.4198 83 41.5C83 18.5802 64.4198 0 41.5 0C18.5802 0 0 18.5802 0 41.5C0 64.4198 18.5802 83 41.5 83Z" fill="#0B6BD0"/>
                <path d="M58.2412 68H24.7588C23.7817 68 23 67.2071 23 66.216V17.784C23 16.7929 23.7817 16 24.7588 16H58.2412C59.2183 16 60 16.7929 60 17.784V66.216C60 67.2071 59.2183 68 58.2412 68Z" fill="#2B3B4E"/>
                <path d="M57 19H27V57H57V19Z" fill="#5EACFF"/>
                <path d="M41 65C42.6569 65 44 63.6569 44 62C44 60.3431 42.6569 59 41 59C39.3431 59 38 60.3431 38 62C38 63.6569 39.3431 65 41 65Z" fill="#324A5E"/>
                <path d="M49.7765 36.3333C49.0391 32.695 45.8212 30 42 30C38.1788 30 34.9609 32.695 34.2235 36.3333C31.743 37.2766 30 39.6348 30 42.4645C30 46.1028 32.9497 49 36.5028 49H47.4972C51.1173 49 54 46.1028 54 42.4645C54 39.6348 52.257 37.2766 49.7765 36.3333Z" fill="white"/>
                <path d="M41.3349 25H41V27.2105H41.3349C47.6316 27.2105 52.7895 32.3684 52.7895 38.6651V39H55V38.6651C55 31.1627 48.8373 25 41.3349 25Z" fill="#F1543F"/>
                <path d="M41.3378 29H41V31.2297H41.3378C45.3919 31.2297 48.7703 34.5405 48.7703 38.6622V39H51V38.6622C50.9324 33.2568 46.6081 29 41.3378 29Z" fill="#F1543F"/>
                <path d="M41.3488 33H41V35.3023H41.3488C43.1628 35.3023 44.6977 36.8372 44.6977 38.6512V39H47V38.6512C47 35.5116 44.4186 33 41.3488 33Z" fill="#F1543F"/>
                <path d="M41.5 37C40.7174 37 40 37.7174 40 38.5C40 39.2826 40.7174 40 41.5 40C42.2826 40 43 39.2826 43 38.5C43 37.7174 42.2826 37 41.5 37Z" fill="#F1543F"/>
                <path d="M31.5773 64H28.4227C27.6186 64 27 63.3478 27 62.5C27 61.6522 27.6186 61 28.4227 61H31.5773C32.3814 61 33 61.6522 33 62.5C33 63.3478 32.3814 64 31.5773 64Z" fill="#324A5E"/>
                <path d="M55.5773 64H52.4227C51.6186 64 51 63.3478 51 62.5C51 61.6522 51.6186 61 52.4227 61H55.5773C56.3814 61 57 61.6522 57 62.5C56.9381 63.3478 56.3196 64 55.5773 64Z" fill="#324A5E"/>
            </g>
            <defs>
                <clipPath id="clip0_399_359">
                    <rect width="83" height="83" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SmartphoneSvg;