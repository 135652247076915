import React from 'react';

function ArrowOpenCardSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="43" height="38" viewBox="0 0 43 38" fill="none">
            <path d="M41.7678 20.7678C42.7441 19.7915 42.7441 18.2085 41.7678 17.2322L25.8579 1.32233C24.8816 0.34602 23.2986 0.34602 22.3223 1.32233C21.346 2.29864 21.346 3.88155 22.3223 4.85786L36.4645 19L22.3223 33.1421C21.346 34.1184 21.346 35.7014 22.3223 36.6777C23.2986 37.654 24.8816 37.654 25.8579 36.6777L41.7678 20.7678ZM0 21.5H40V16.5H0V21.5Z" fill="#0D3158"/>
        </svg>
    );
}

export default ArrowOpenCardSvg;