import React from 'react';
import styled from "styled-components";
import CardImage from "../images/EmbeddedSwScreen1.png";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding-top: 240px;

  @media (max-width: 980px) {
    padding-top: 200px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Title = styled.div`
  color: #F4B141;

  font-family: Montserrat, serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 1230px) {
    font-size: 46px;
  }

  @media (max-width: 890px) {
    font-size: 32px;
  }
`;

const TextImage = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 80px;

  & > img {
    border-radius: 50px;
    @media (max-width: 1230px) {
      width: 50%;
    }

    @media (max-width: 890px) {
      width: 100%;
      object-fit: cover;
    }

    @media (max-width: 620px) {
      aspect-ratio: 1 / 1;
    }
  }

  @media (max-width: 890px) {
    flex-direction: column;
    gap: 50px;
    margin-top: 50px;
  }
`;

const Text = styled.div`
  color: #023265;
  font-family: Montserrat, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

function EmbeddedSwScreen1(props) {
    return (
        <Component>
            <Content>
                <Title>Embedded SW</Title>
                <TextImage>
                    <Text>
                        <p>We specialize in creating robust and efficient embedded software solutions that power a wide
                            range of devices and systems. From small-scale microcontrollers to complex embedded systems,
                            we have the expertise to develop software that meets the unique requirements of your
                            project. </p>
                        <p>Our team's extensive knowledge in embedded software development allows us to design solutions
                            that optimize performance, memory utilization and power consumption</p>
                    </Text>
                    <img src={CardImage} alt="CardImage"/>
                </TextImage>
            </Content>
        </Component>
    );
}

export default EmbeddedSwScreen1;