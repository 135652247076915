import React from 'react';

function IconInSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
            <rect width="49" height="49" rx="11" fill="#F4B141"/>
            <path d="M49 33.9284C47.1983 38.1537 44.2198 41.8041 40.401 44.4673C36.5822 47.1305 32.0752 48.7005 27.3889 49L14.8517 36.7858L17.8112 21.4366L14 17.7365L18.8319 13L28.688 22.6022L29.2812 22.0242L36.2176 21.4786L49 33.9284Z" fill="url(#paint0_linear_409_34)"/>
            <path d="M20 15.4966C20.0007 16.1891 19.7959 16.8663 19.4115 17.4424C19.0272 18.0185 18.4806 18.4676 17.8409 18.7329C17.2013 18.9983 16.4973 19.0679 15.818 18.9329C15.1388 18.798 14.5148 18.4646 14.0251 17.9749C13.5354 17.4852 13.202 16.8612 13.0671 16.182C12.9321 15.5027 13.0017 14.7987 13.2671 14.1591C13.5324 13.5194 13.9815 12.9728 14.5576 12.5885C15.1337 12.2041 15.8109 11.9993 16.5034 12C17.4305 12.0009 18.3193 12.3696 18.9749 13.0251C19.6304 13.6807 19.9991 14.5695 20 15.4966Z" fill="#0B69C7"/>
            <path d="M18.1999 20H14.8001C14.3582 20 14 20.3841 14 20.8578V36.1422C14 36.6159 14.3582 37 14.8001 37H18.1999C18.6418 37 19 36.6159 19 36.1422V20.8578C19 20.3841 18.6418 20 18.1999 20Z" fill="#0B69C7"/>
            <path d="M38 28.9309V36.2237C38 36.4296 37.9152 36.627 37.7643 36.7726C37.6134 36.9182 37.4088 37 37.1953 37H33.1994C32.9859 37 32.7813 36.9182 32.6304 36.7726C32.4795 36.627 32.3947 36.4296 32.3947 36.2237V29.1579C32.3947 28.102 32.7118 24.5526 29.5375 24.5526C27.0758 24.5526 26.5746 26.9934 26.4689 28.0888V36.2401C26.4645 36.442 26.3787 36.6343 26.2297 36.7762C26.0808 36.918 25.8804 36.9983 25.6711 37H21.8047C21.6989 37.0004 21.594 36.9806 21.4962 36.9418C21.3984 36.9029 21.3095 36.8458 21.2347 36.7736C21.1599 36.7014 21.1006 36.6157 21.0603 36.5213C21.0201 36.4269 20.9996 36.3257 21 36.2237V21.0263C20.9996 20.9241 21.02 20.8228 21.0603 20.7282C21.1005 20.6337 21.1597 20.5477 21.2345 20.4753C21.3092 20.4028 21.3981 20.3453 21.4959 20.3061C21.5938 20.2669 21.6987 20.2467 21.8047 20.2467H25.6711C25.7783 20.245 25.8849 20.2638 25.9845 20.3022C26.0841 20.3406 26.1748 20.3978 26.2513 20.4703C26.3278 20.5429 26.3885 20.6294 26.43 20.7249C26.4715 20.8204 26.4928 20.9228 26.4928 21.0263V22.3421C27.4065 21.0263 28.7635 20 31.6514 20C38.0375 19.9967 38 25.7632 38 28.9309Z" fill="#0B69C7"/>
            <defs>
                <linearGradient id="paint0_linear_409_34" x1="17.8211" y1="22.699" x2="40.5569" y2="44.1834" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#DFA037"/>
                    <stop offset="0.19" stopColor="#D79B38" stopOpacity="0.8"/>
                    <stop offset="0.6" stopColor="#E0A035" stopOpacity="0.5"/>
                    <stop offset="0.97" stopColor="#DEA13B" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default IconInSvg;