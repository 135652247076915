import React from 'react';
import Header from "../components/Header";
import styled from "styled-components";
import Ticker from "../components/Ticker";
import Footer from "../components/Footer";
import IoTScreen1 from "../components/IoTScreen1";
import IoTScreen2 from "../components/IoTScreen2";

const Page = styled.div`
  width: min(1920px, 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

let TextForTicker = ["Patient Health Monitoring system with a PPG sensor and a blood pressure sensor",
    "Healthy lifestyle recommendations using Smart watch gyroscope, accelerometer and PPG sensors ",
    "Visual monitoring of storage contents by using video camera as an image sensor",
    " Monitoring the state of industrial equipment based on the machine vibration analysis"]

function IoTPage(props) {
    return (
        <Page>
            <Header lightVersion/>
            <IoTScreen1/>
            <IoTScreen2/>
            <Ticker TextForTicker={TextForTicker}/>
            <Footer/>
        </Page>
    );
}

export default IoTPage;


