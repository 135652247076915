import React from 'react';

function PulleySvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="83" height="83" viewBox="0 0 83 83" fill="none">
            <g clipPath="url(#clip0_399_260)">
                <path d="M41.5 83C64.4198 83 83 64.4198 83 41.5C83 18.5802 64.4198 0 41.5 0C18.5802 0 0 18.5802 0 41.5C0 64.4198 18.5802 83 41.5 83Z" fill="#F4B141"/>
                <path d="M67.1328 28.0578V53.9171C67.1328 60.1211 62.0482 65.1683 55.7985 65.1683C49.5489 65.1683 44.4643 60.121 44.4643 53.9128L44.4248 32.0829C44.4248 23.2148 37.1567 16 28.2232 16C19.2897 16 12.0216 23.2147 12.0216 32.0829V52.7018L9.4417 50.1408L6 53.5575L14.4621 61.9573L22.9239 53.557L19.4822 50.1405L16.8889 52.715V32.0829C16.8889 25.8789 21.9735 20.8317 28.2232 20.8317C34.4729 20.8317 39.5575 25.879 39.5575 32.0872L39.5967 53.9171C39.5967 62.7852 46.8648 70 55.7984 70C64.7319 70 72 62.7852 72 53.9171V28.0578H67.1328Z" fill="white"/>
                <path d="M27.9126 19C20.229 19 14 25.268 14 33C14 40.732 20.2288 47 27.9126 47C27.9825 47 28.0519 46.9984 28.1215 46.9973L29 32.9998L28.1215 19.0024C28.0519 19.0015 27.9825 19 27.9126 19Z" fill="#FF8870"/>
                <path d="M42 32.9999C42 25.2691 35.7324 19.0021 28 19V47C35.7323 46.9977 42 40.7306 42 32.9999Z" fill="#F56F00"/>
                <path d="M27.8955 37.4846C25.4424 37.4846 23.4537 35.4768 23.4537 33.0002C23.4537 30.5234 25.4424 28.5156 27.8955 28.5156C27.9666 28.5156 28.0373 28.5177 28.1076 28.521L28.9998 25.862L28.1076 22H25.9553L25.5083 24.391C24.8196 24.5852 24.1645 24.8616 23.5544 25.2088L21.5633 23.8368L18.8193 26.607L20.1783 28.6173C19.8344 29.2333 19.5608 29.8944 19.3683 30.5899L17 31.0412V34.9588L19.3683 35.4101C19.5607 36.1055 19.8344 36.7667 20.1783 37.3827L18.8193 39.393L21.5633 42.1632L23.5546 40.7912C24.1646 41.1384 24.8196 41.4146 25.5085 41.609L25.9554 44H28.1078L29 40.4701L28.1078 37.479C28.0373 37.4825 27.9666 37.4846 27.8955 37.4846Z" fill="#0B6CD2"/>
                <path d="M39 34.9588V31.0412L36.6082 30.5899C36.4139 29.8945 36.1374 29.2333 35.7901 28.6173L37.1626 26.6068L34.3914 23.8366L32.3803 25.2088C31.764 24.8616 31.1028 24.5852 30.4069 24.391L29.9556 22H28V28.5156C30.4758 28.5177 32.482 30.5247 32.482 33C32.482 35.4753 30.4757 37.4821 28 37.4844V44H29.9556L30.4069 41.609C31.1026 41.4148 31.764 41.1385 32.3803 40.7912L34.3914 42.1632L37.1626 39.393L35.7901 37.3827C36.1374 36.7667 36.4137 36.1056 36.6082 35.4101L39 34.9588Z" fill="#023265"/>
                <path d="M55.7048 40C48.1359 40 42 46.268 42 54C42 61.732 48.1359 68 55.7048 68C55.7454 68 55.7855 67.9989 55.8261 67.9984L57 53.452L55.8259 40.0016C55.7855 40.0013 55.7453 40 55.7048 40Z" fill="#FF8870"/>
                <path d="M70 54.0001C70 46.2729 63.7333 40.0081 56 40V68C63.7333 67.9921 70 61.7271 70 54.0001Z" fill="#F56F00"/>
                <path d="M55.6899 58.4846C53.283 58.4846 51.3319 56.4768 51.3319 54.0002C51.3319 51.5234 53.2831 49.5156 55.6899 49.5156C55.731 49.5156 55.7717 49.5175 55.8126 49.5189L57 47.1279L55.8124 43H53.7863L53.3478 45.391C52.6718 45.5854 52.0293 45.8616 51.4308 46.2088L49.4771 44.8368L46.785 47.607L48.1184 49.6173C47.781 50.2333 47.5125 50.8944 47.3236 51.5899L45 52.0412V55.9588L47.3236 56.4101C47.5124 57.1055 47.781 57.7667 48.1184 58.3827L46.785 60.393L49.4771 63.1632L51.4308 61.7912C52.0294 62.1384 52.6718 62.4146 53.3478 62.609L53.7863 65H55.8124L57 61.9399L55.8124 58.4815C55.7717 58.4826 55.731 58.4846 55.6899 58.4846Z" fill="#0B6CD2"/>
                <path d="M67 55.9587V52.041L64.6058 51.5898C64.4111 50.8944 64.1345 50.2332 63.7869 49.6171L65.1608 47.6068L62.3868 44.8366L60.3736 46.2088C59.7569 45.8616 59.0948 45.5854 58.3983 45.391L57.9465 43H56V49.5159C58.4732 49.524 60.4755 51.5283 60.4755 54C60.4755 56.4717 58.4732 58.4759 56 58.4841V65H57.9465L58.3983 62.609C59.0947 62.4148 59.7569 62.1386 60.3736 61.7912L62.3868 63.1632L65.1608 60.393L63.7869 58.3827C64.1345 57.7667 64.4111 57.1056 64.6058 56.4101L67 55.9587Z" fill="#023265"/>
            </g>
            <defs>
                <clipPath id="clip0_399_260">
                    <rect width="83" height="83" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default PulleySvg;