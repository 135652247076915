import React from 'react';
import styled from "styled-components";
import TickSquareSvg from "../svg/TickSquareSvg";

const Component = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding-top: 150px;

  @media (max-width: 980px) {
    padding-top: 100px;
  }
`;

const Content = styled.div`
  max-width: 1172px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Title = styled.div`
  color: #023265;

  font-family: Montserrat, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 980px) {
    font-size: 26px;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px 50px;
  margin-top: 80px;

  @media (max-width: 1230px) {
    gap: 40px 20px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr ;
    
    svg {
      min-width: 28px;
      max-width: 28px;
      gap: 35px;
    }
  }
`;

const ItemList = styled.div`
  color: #023265;
  font-family: Montserrat,serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 800px) {
    font-size: 22px;
    gap: 30px;
    align-items: flex-start;
  }
`;

function EmbeddedSwScreen2(props) {
    return (
        <Component>
            <Content>
                <Title>Our Experience</Title>
                <List>
                    <ItemList><TickSquareSvg/>Networking</ItemList>
                    <ItemList><TickSquareSvg/>BSP</ItemList>
                    <ItemList><TickSquareSvg/>Video data processing</ItemList>
                    <ItemList><TickSquareSvg/>Driver implementation</ItemList>
                    <ItemList><TickSquareSvg/>Multicore BSP with different OS</ItemList>
                    <ItemList><TickSquareSvg/>Performance optimization</ItemList>
                    <ItemList><TickSquareSvg/>Power consumption optimization</ItemList>
                    <ItemList><TickSquareSvg/>FW update system</ItemList>
                </List>
            </Content>
        </Component>
    );
}

export default EmbeddedSwScreen2;