import styled from "styled-components";
import Main from "./pages/Main";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import EmbeddedSwPage from "./pages/EmbeddedSWPage";
import IoTPage from "./pages/IoTPage";
import AlgorithmicPage from "./pages/AlgorithmicPage";
import AutomatedTestingPage from "./pages/AutomatedTestingPage";


const StyledApp = styled.div`
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: #E5E7EA;
`;


function App() {

    return (
        <StyledApp>
            <Router>
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/embeddedsw" element={<EmbeddedSwPage/>}/>
                    <Route path="/iot" element={<IoTPage/>}/>
                    <Route path="/algorithmic" element={<AlgorithmicPage/>}/>
                    <Route path="/automatedtesting" element={<AutomatedTestingPage/>}/>
                </Routes>
            </Router>
        </StyledApp>
    );
}

export default App;
