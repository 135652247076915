import React from 'react';

function TickSquareSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <path d="M26.3023 40H15.6977C6.10047 40 2 35.8995 2 26.3023V15.6977C2 6.10047 6.10047 2 15.6977 2H26.3023C35.8995 2 40 6.10047 40 15.6977V26.3023C40 35.8995 35.8995 40 26.3023 40ZM15.6977 4.65116C7.54977 4.65116 4.65116 7.54977 4.65116 15.6977V26.3023C4.65116 34.4502 7.54977 37.3488 15.6977 37.3488H26.3023C34.4502 37.3488 37.3488 34.4502 37.3488 26.3023V15.6977C37.3488 7.54977 34.4502 4.65116 26.3023 4.65116H15.6977Z" fill="#F4B141"/>
            <path d="M18.0848 28C17.7446 28 17.4215 27.8549 17.1833 27.601L12.3699 22.4681C11.8767 21.9421 11.8767 21.0715 12.3699 20.5455C12.8632 20.0195 13.6796 20.0195 14.1728 20.5455L18.0848 24.7171L26.8272 15.3945C27.3204 14.8685 28.1368 14.8685 28.6301 15.3945C29.1233 15.9205 29.1233 16.7911 28.6301 17.3171L18.9862 27.601C18.7481 27.8549 18.425 28 18.0848 28Z" fill="#023265"/>
            <path d="M26.3023 40H15.6977C6.10047 40 2 35.8995 2 26.3023V15.6977C2 6.10047 6.10047 2 15.6977 2H26.3023C35.8995 2 40 6.10047 40 15.6977V26.3023C40 35.8995 35.8995 40 26.3023 40Z" fill="#F4B141"/>
            <path d="M18.0848 28C17.7446 28 17.4215 27.8549 17.1833 27.601L12.3699 22.4681C11.8767 21.9421 11.8767 21.0715 12.3699 20.5455C12.8632 20.0195 13.6796 20.0195 14.1728 20.5455L18.0848 24.7171L26.8272 15.3945C27.3204 14.8685 28.1368 14.8685 28.6301 15.3945C29.1233 15.9205 29.1233 16.7911 28.6301 17.3171L18.9862 27.601C18.7481 27.8549 18.425 28 18.0848 28Z" fill="#023265"/>
        </svg>
    );
}

export default TickSquareSvg;