import React from 'react';

function TextalignJustifycenterSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <path d="M33.9231 14.4599H8.07692C7.48821 14.4599 7 13.9717 7 13.383C7 12.7943 7.48821 12.3061 8.07692 12.3061H33.9231C34.5118 12.3061 35 12.7943 35 13.383C35 13.9717 34.5118 14.4599 33.9231 14.4599Z" fill="white"/>
            <path d="M33.9231 21.6394H8.07692C7.48821 21.6394 7 21.1512 7 20.5625C7 19.9738 7.48821 19.4856 8.07692 19.4856H33.9231C34.5118 19.4856 35 19.9738 35 20.5625C35 21.1512 34.5118 21.6394 33.9231 21.6394Z" fill="white"/>
            <path d="M33.9231 28.8189H8.07692C7.48821 28.8189 7 28.3307 7 27.742C7 27.1533 7.48821 26.6651 8.07692 26.6651H33.9231C34.5118 26.6651 35 27.1533 35 27.742C35 28.3307 34.5118 28.8189 33.9231 28.8189Z" fill="white"/>
            <rect x="1" y="1" width="40" height="40" rx="4" stroke="#F4B141" strokeWidth="2"/>
        </svg>
    );
}

export default TextalignJustifycenterSvg;