import React from 'react';

function PlaceholderSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <g clipPath="url(#clip0_399_1233)">
                <path d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z" fill="#F4B141"/>
                <path d="M20.5 35C26.8513 35 32 33.6569 32 32C32 30.3431 26.8513 29 20.5 29C14.1487 29 9 30.3431 9 32C9 33.6569 14.1487 35 20.5 35Z" fill="#F4B141"/>
                <path d="M21 29V35C27.6274 35 33 33.6569 33 32C33 30.3431 27.6274 29 21 29Z" fill="#D1993B"/>
                <path d="M32 15.1153C32 23.359 21.5 34 21.5 34C21.5 34 11 23.359 11 15.1153C11 9.52874 15.701 5 21.5 5C27.299 5 32 9.52874 32 15.1153Z" fill="#4A7AFF"/>
                <path d="M21 5V34C21 34 31 23.359 31 15.1153C31 9.52874 26.5229 5 21 5Z" fill="#0053BF"/>
                <path d="M20.5 21C24.0899 21 27 18.0899 27 14.5C27 10.9101 24.0899 8 20.5 8C16.9101 8 14 10.9101 14 14.5C14 18.0899 16.9101 21 20.5 21Z" fill="white"/>
                <path d="M21 8V21C24.866 21 28 18.0899 28 14.5C28 10.9101 24.866 8 21 8Z" fill="#E9EDF5"/>
            </g>
            <defs>
                <clipPath id="clip0_399_1233">
                    <rect width="42" height="42" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default PlaceholderSvg;