import React from 'react';
import Header from "../components/Header";
import styled from "styled-components";
import Footer from "../components/Footer";
import AlgorithmicScreen1 from "../components/AlgorithmicScreen1";
import AlgorithmicScreen2 from "../components/AlgorithmicScreen2";

const Page = styled.div`
  width: min(1920px, 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

function AlgorithmicPage(props) {
    return (
        <Page>
            <Header lightVersion/>
            <AlgorithmicScreen1/>
            <AlgorithmicScreen2/>
            <Footer/>
        </Page>
    );
}

export default AlgorithmicPage;