import React from 'react';

function CloudDataSvg(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="83" viewBox="0 0 83 83" fill="none">
            <g clipPath="url(#clip0_399_519)">
                <path d="M41.5 83C64.4198 83 83 64.4198 83 41.5C83 18.5802 64.4198 0 41.5 0C18.5802 0 0 18.5802 0 41.5C0 64.4198 18.5802 83 41.5 83Z" fill="#F4B141"/>
                <path d="M83 41.5124C83 40.2029 82.9365 38.9085 82.8177 37.6302C74.0141 28.8258 64.189 19.0003 64.189 19.0003L58.2655 24.9045C58.2655 24.9045 59.7441 26.3861 59.7548 26.3971V34.5329H55.5557C55.5382 34.5156 52.0991 31.0784 52.0991 31.0784L33.5607 27.6361L24.9235 19L19 24.9042C19 24.9042 20.4786 26.3858 20.4893 26.3968V37.3244L23.4916 40.2415L24.7705 45.6997H20.4893V57.1999L19.0086 64.1863C19.0086 64.1863 28.8329 74.0127 37.6361 82.8175C38.9145 82.9365 40.2087 83 41.5179 83C64.4279 83 83 64.4254 83 41.5124Z" fill="#E69904"/>
                <path d="M63 38H53V35.1325H60.1699V25H63V38Z" fill="#CFD7E6"/>
                <path d="M31 38H21V25H23.8301V35.1325H31V38Z" fill="#E1E6F0"/>
                <path d="M23.8301 59H21V46H31V48.8675H23.8301V59Z" fill="#E1E6F0"/>
                <path d="M63 59H60.1699V48.8675H53V46H63V59Z" fill="#CFD7E6"/>
                <path d="M22 26C19.7943 26 18 24.2057 18 22C18 19.7943 19.7943 18 22 18C24.2057 18 26 19.7943 26 22C26 24.2057 24.2057 26 22 26Z" fill="#0B6CD2"/>
                <path d="M22 66C19.7943 66 18 64.2057 18 62C18 59.7943 19.7943 58 22 58C24.2057 58 26 59.7943 26 62C26 64.2057 24.2057 66 22 66Z" fill="#0B6CD2"/>
                <path d="M62 26C59.7943 26 58 24.2057 58 22C58 19.7943 59.7943 18 62 18C64.2057 18 66 19.7943 66 22C66 24.2057 64.2057 26 62 26Z" fill="#023265"/>
                <path d="M62 66C59.7943 66 58 64.2057 58 62C58 59.7943 59.7943 58 62 58C64.2057 58 66 59.7943 66 62C66 64.2057 64.2057 66 62 66Z" fill="#023265"/>
                <path d="M41.9999 64C37.8479 64 33.9417 62.2717 31 59.1335L33.0008 57C35.4078 59.568 38.6037 60.9825 41.9999 60.9825C45.3962 60.9825 48.5922 59.568 50.9992 57L53 59.1335C50.0581 62.2718 46.152 64 41.9999 64Z" fill="#0B6CD2"/>
                <path d="M41.9999 58C39.3566 58 36.8709 56.948 35 55.0394L37.0008 53C39.676 55.7295 44.3242 55.7295 46.9992 53L49 55.0394C47.129 56.948 44.6432 58 41.9999 58Z" fill="#0B6CD2"/>
                <path d="M55.1942 35.3599C54.4826 31.7299 50.7258 29.0349 46.7126 29.915C45.546 28.7326 44.0944 27.8799 42.5003 27.4125C41.5893 27.1374 40.6221 27 39.6541 27C34.5878 27 30.4041 30.6576 29.7779 35.3599C26.5047 35.9926 24 38.7701 24 42.125C24 45.9201 27.1878 49 31.1154 49H41.077V47.625C41.077 46.8549 41.703 46.2501 42.5 46.2501C43.297 46.2501 43.923 46.8549 43.923 47.625V49H53.8846C57.8124 49 61 45.9199 61 42.125C61.0005 38.7701 58.4958 35.9651 55.1942 35.3599Z" fill="#F3F5F9"/>
                <path d="M60 41.9937C60 45.8613 56.8984 49 53.077 49H43.3846V47.5988C43.3846 46.814 42.7755 46.1975 42 46.1975V27C43.551 27.4764 44.9633 28.3451 46.0984 29.5503C50.0032 28.6534 53.6584 31.3999 54.3508 35.0993C57.563 35.716 60 38.5746 60 41.9937Z" fill="#E1E6F0"/>
                <path d="M42 51C39.7943 51 38 49.2057 38 47C38 44.7943 39.7943 43 42 43C44.2057 43 46 44.7943 46 47C46 49.2057 44.2057 51 42 51Z" fill="#0B6CD2"/>
                <path d="M50.9994 56C48.5923 58.568 45.3964 59.9825 42 59.9825V63C46.1521 63 50.0583 61.2717 53 58.1335L50.9994 56Z" fill="#023265"/>
                <path d="M49 54.0395L46.9992 52C45.6616 53.3649 43.8308 54.0472 42 54.0472V57C44.6433 57.0002 47.1291 55.9481 49 54.0395Z" fill="#023265"/>
                <path d="M46 47.5C46 45.0186 44.2057 43 42 43V52C44.2057 52 46 49.9814 46 47.5Z" fill="#023265"/>
            </g>
            <defs>
                <clipPath id="clip0_399_519">
                    <rect width="83" height="83" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default CloudDataSvg;